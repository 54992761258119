import React, { useEffect, useMemo } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Form, Input, Tooltip } from 'antd';
import { SearchForm, SearchFormAction, SearchFormInputContainer, SearchFormText, TableHeader } from './Styles';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Button } from '../buttons/buttons';

export const DataTableHeader = ({
  updateSearchValue,
  searchValue,
  actions,
  itemsName = 'records',
  searchPlaceholder = 'search',
  enabledSearch = true,
  totalInPage = 0,
  totalRecords = 0,
  isLoading = false,
  reduceSearch = false,
  enabledBtnSearch = false,
}) => {
  const [searchForm] = Form.useForm();
  const { translate: t } = useDictionaryTranslation();

  const searchCustomers = () => {
    const value = searchForm.getFieldValue('searchValue');
    updateSearchValue(value);
  };

  const clearSearch = () => {
    updateSearchValue('');
    searchForm.resetFields();
  };

  const searchTextComponent = useMemo(() => {
    return (
      <SearchFormText>
        {isLoading && !totalRecords ? (
          t('loadingItems', { itemsName })
        ) : (
          <>
            {totalInPage} {t(itemsName)}
            <span className="small"> {t('fromNAvailable', { n: totalRecords })}</span>
          </>
        )}
      </SearchFormText>
    );
  }, [isLoading, itemsName, t, totalInPage, totalRecords]);

  useEffect(() => {
    searchForm.setFieldsValue({
      searchValue,
    });
  }, [searchForm, searchValue]);

  return (
    <TableHeader>
      {searchTextComponent}
      <SearchForm form={searchForm} onFinish={searchCustomers}>
        <SearchFormInputContainer reduceSearch={reduceSearch} enabledBtnSearch={enabledBtnSearch}>
          {enabledSearch ? (
            <Form.Item name="searchValue" initialValue={searchValue}>
              <Input
                placeholder={t(searchPlaceholder)}
                bordered={false}
                suffix={searchValue ? <Button type="danger" onClick={clearSearch} icon={<CloseOutlined />} /> : null}
              />
            </Form.Item>
          ) : null}
        </SearchFormInputContainer>
        <SearchFormAction>
          {enabledSearch &&
            (!enabledBtnSearch ? (
              <Tooltip title={t('search')}>
                <Button type="secondary" htmlType="submit" className="add-service" icon={<SearchOutlined />} />
              </Tooltip>
            ) : null)}

          {actions}
        </SearchFormAction>
      </SearchForm>
    </TableHeader>
  );
};

DataTableHeader.propTypes = {
  totalInPage: PropTypes.number,
  totalRecords: PropTypes.number,
  updateSearchValue: PropTypes.func,
  actions: PropTypes.object,
  enabledSearch: PropTypes.bool,
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  itemsName: PropTypes.string,
  isLoading: PropTypes.bool,
  reduceSearch: PropTypes.bool,
  enabledBtnSearch: PropTypes.bool,
};
