import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class HeadquartersService {
  static async getHeadquarters({ page, search, idsHeadquarters }) {
    const params = {
      page,
      search,
      idsHeadquarters,
    };
    const { data } = await DataService.get(API.headquarters.root, params);
    return {
      headquarters: data.headquarters,
      total: data.total,
      pageSize: data.pageSize,
    };
  }

  static async getDefaulHeadquarter() {
    const { data } = await DataService.get(`${API.headquarters.root}/${API.headquarters.defaultHeadquarter}`);
    return {
      headquarter: data.defaultHeadquarter,
    };
  }

  static async getHeadquarterById(headquarterId) {
    const { data } = await DataService.get(`${API.headquarters.root}/${headquarterId}`);
    return {
      headquarter: data.headquarter,
    };
  }

  static async createHeadquarter(data) {
    const headquarterData = {
      name: data.name,
      reference: data.reference,
      phone: data.phone,
      address: data.address,
      idColor: data.idColor,
    };
    const { data: newData } = await DataService.post(API.headquarters.root, headquarterData);
    return {
      headquarter: newData.headquarter,
    };
  }

  static async updateHeadquarter(id, data) {
    const headquarterData = {
      name: data.name,
      reference: data.reference,
      phone: data.phone,
      address: data.address,
      idColor: data.idColor,
    };
    const { data: newData } = await DataService.patch(`${API.headquarters.root}/${id}`, headquarterData);
    return {
      headquarter: newData.headquarter,
    };
  }

  static async removeHeadquarters(id) {
    const { data } = await DataService.delete(`${API.headquarters.root}/${id}`);
    return {
      headquarter: data.headquarter,
    };
  }

  static async getHeadquarterLockedDates(datalocks) {
    const startDate = encodeURIComponent(datalocks.startDate);
    const endDate = encodeURIComponent(datalocks.endDate);
    
    const idsHeadquarters = '';

    try {
      const { data } = await DataService.get(
        `${API.headquarters.headquarterLockedDates}?startDate=${startDate}&endDate=${endDate}&idsHeadquarters[]=${idsHeadquarters}`,
      );

      return {
        headquarter: data.history,
      };
    } catch (error) {
      console.error('Error al obtener datos de la API:', error);
      throw error;
    }
  }

}

export { HeadquartersService };
