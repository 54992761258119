const accountThemes = [
  { id: 1, name: 'fuchsiaTheme', principalColor: '#F50057', secondColor: '#8E24AA', thirdColor: '#2196F3' },
  { id: 2, name: 'blueTheme', principalColor: '#1976D2', secondColor: '#4DD0E1', thirdColor: '#009EFA' },
  { id: 3, name: 'greenTheme', principalColor: '#00896F', secondColor: '#9DCB5F', thirdColor: '#388E3C' },
  { id: 4, name: 'grayTheme', principalColor: '#354952', secondColor: '#00ACC1', thirdColor: '#607D8B' },
  { id: 5, name: 'redTheme', principalColor: '#E53935', secondColor: '#607D8B', thirdColor: '#E53935' },
  { id: 6, name: 'orangeTheme', principalColor: '#FF5722', secondColor: '#607D8B', thirdColor: '#EF6C00' },
  { id: 7, name: 'purpleTheme', principalColor: '#512DA8', secondColor: '#8E24AA', thirdColor: '#6A1B9A' },
  { id: 8, name: 'blackTheme', principalColor: '#444343', secondColor: '#757575', thirdColor: '#212121' },
];

const DEFAULT_DOCUMENT_TITLE = 'Suite';

export { accountThemes, DEFAULT_DOCUMENT_TITLE };
