import Proptypes from 'prop-types';
import { UserInfoCard } from './UserInfoCard';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { profileTypes } from '../../../config/constants/userConstants';

export const UsertypeDescription = ({ userType }) => {
  const { translate: t } = useDictionaryTranslation();
  const textByProfile = {
    [profileTypes.TECHNICIAN.id]: {
      text: 'userTypeTechnicianDescription',
      color: '#10AE05',
    },

    [profileTypes.INTERESTED.id]: {
      text: 'userTypeInterestedDescription',
      color: '#037CD1',
    },

    [profileTypes.OPERATOR.id]: {
      text: 'userTypeOperatorDescription',
      color:  '#5d42c4'
    }
  };

  const profileInfo = textByProfile[userType];
  if (profileInfo == null) return null;
  return (
    <>
      <UserInfoCard color={profileInfo.color} title={t('userTypeDescriptiontitle')} text={t(profileInfo.text)} margin='34px' />
    </>
  );
};

UsertypeDescription.propTypes = {
  userType: Proptypes.string,
};
