import { Button, Space } from 'antd';
import styled from 'styled-components';

const TextUsers = styled.span`
  color: #9ba3b6;
  font-size: 100%;
  & .count {
    margin-right: 3px;
  }
`;

const SpaceText = styled(Space)`
  margin-bottom: 2%;
  margin-left: 2.1%;
`;

const ButtonActionDevice = styled(Button)`
  margin-top: 4px;
  box-shadow: none;
  padding: 0px 0px;
  min-width: 40px;
  max-height: 37px;
  background-color: #d9d9d9;

  .icons {
    margin-top: 3px;
    width: 17px;
    ${(props) => (props.isTechnician ? 'color: #5c617f;' : 'color: #b8b8b8;')}
  }
`;

export { TextUsers, SpaceText, ButtonActionDevice };
