import styled from 'styled-components';
import { Button } from '../buttons';

const SearchButtonAction = styled(({ primaryColor, textColor, iconWidth, width, height, ...rest }) => (
  <Button {...rest} />
))`
  box-shadow: none;
  padding: 0;
  min-width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  background-color: ${(props) => props.primaryColor};
  color: ${(props) => props.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.98);
  }

  .icons {
    width: ${(props) => props.iconWidth};
  }
`;

export { SearchButtonAction };
