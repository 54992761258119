const incidentsStatus = {
  ASSIGNED: 1,
  UNASSIGNED: 2,
  IN_CONTEST: 3,
  ACCEPTED: 4,
  REJECTED: 5,
  IN_PROCESS: 6,
  PENDING_FINISH: 7,
  FINISHED: 8,
  CANCELLED: 9,
  PETITION: 10,
  NOT_COMPLETED: 11,
};

export { incidentsStatus };
