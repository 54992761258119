import PropTypes from 'prop-types';
import { useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ContentStatus } from './style';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { TagTable } from '../Style';
import { DataTable } from '../../../components/dataTable/data-table';
import { SearchButtonComponent } from '../../../components/buttons/search-button/SearchButton';

export const PendingsBoardTable = ({ tableData, isLoading, settingData }) => {
  const { translate: t } = useDictionaryTranslation();

  const changeRowColor = (record) => {
    return {
      style: { background: record.colorHighlight }
    }
  }

  const columns = useMemo(
    () => [
      {
        title: t('status'),
        show: settingData?.fields.status,
        width: '12%',
        render: (_, record) => (
          <>
            <ContentStatus
              style={{
                background: record.infoStatus?.color,
                opacity: '0.6',
              }}
            >
              <TagTable>{record.infoStatus?.name}</TagTable>
            </ContentStatus>
          </>
        ),
      },
      {
        title: t('Id'),
        show: settingData?.fields.id,
        dataIndex: 'privateId',
        key: '1',
        width: '5%',
      },
      {
        title: t('serviceName'),
        show: settingData?.fields.name,
        dataIndex: 'name',
        key: '2',
        width: '26%',
      },
      {
        title: t('finalCustomer'),
        show: settingData?.fields.customer,
        render: (_, record) => record.infoFinalCustomer?.fullname,
        key: '3',
        width: '11%',
      },
      {
        title: t('serviceType'),
        show: settingData?.fields.serviceType,
        render: (_, record) => record.infoServiceType?.name,
        key: '4',
        width: '25%',
      },
      {
        title: t('dateAndHour'),
        show: settingData?.fields.assignedDate,
        render: (_, record) => moment(record.start).format('DD/MM/YYYY HH:mm'),
        dataIndex: 'start',
        key: '5',
        width: '12%',
      },
      {
        title: t('technician'),
        show: settingData?.fields.assignedUser,
        render: (_, record) => record.infoAssignedUser?.profile.name,
        key: '6',
        width: '8%',
      },
      {
        show: true,
        render: (_, record) => (
          <Link to={`/services-detail/${record?._id}/summary`}>
            <SearchButtonComponent type="lightgrey" width="25px" />
          </Link>
        ),
        key: '6',
        width: '1%',
      },
    ],
    [settingData],
  );

  return (
    <>
      <DataTable
        columns={columns.filter((column) => column.show)}
        isLoading={isLoading}
        tableData={tableData}
        showPagination={false}
        onRow={changeRowColor}
        headerData={{
          show: false,
        }}
      />
    </>
  );
};

PendingsBoardTable.propTypes = {
  settingData: PropTypes.object,
  tableData: PropTypes.func,
  isLoading: PropTypes.bool,
};
