import { Row } from 'antd';
import styled from 'styled-components';

const ContentStatus = styled(Row)`
  height: 100% !important;
  width: 100% !important;
  font-size: 13px !important;
  text-align: center;
  border-radius: 1em 1em !important;
  min-height: 35px;
  justify-content: center;
  justify-self: center;
  align-content: center;
  align-self: center;
  padding: 5px;

`;

export { ContentStatus };
