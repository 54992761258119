import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownSelect } from '../dropdown-select/DropdownSelect';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { UsersService } from '../../services/usersService';
import { profileTypes } from '../../config/constants/userConstants';

export const SelectUser = ({ placeholder, selectedUser, onChange, getData, multiple, autoselect, ...props }) => {
  const { specialties, idProfile, headquarter, headquarters, excludedIds, allowCurrentUser } = props;
  const { translate: t } = useDictionaryTranslation();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectUser = (item) => {
    if (multiple) {
      onChange(item);
      return;
    }
    let selectedOption;
    if (item) selectedOption = users.find((s) => s._id === item.value);
    onChange(selectedOption);
  };

  const getUsers = useCallback(
    async (search) => {
      if (autoselect && idProfile === profileTypes.TECHNICIAN.id && (!headquarter || !specialties)) return;

      setLoading(true);
      try {
        const { users: usersList } = await getData({
          search,
          specialties,
          idProfile,
          headquarter,
          headquarters,
          excludedIds,
          allowCurrentUser,
        });
        setUsers(usersList);

        if (usersList?.length === 1 && !selectedUser) {
          const selectedItem = {
            ...usersList[0],
            data: usersList[0],
            value: usersList[0]._id,
            label: usersList[0].profile.name,
          };
          if (multiple) {
            selectUser([selectedItem]);
          } else {
            selectUser(selectedItem);
          }
        }
      } catch (error) {
        setUsers([]);
      } finally {
        setLoading(false);
      }
    },
    [specialties, idProfile, headquarter, headquarters, excludedIds],
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getOptionData = useCallback((user) => {
    return {
      value: user._id,
      label: user.profile.name,
    };
  }, []);

  return (
    <DropdownSelect
      placeholderText={placeholder || t('userPlaceholder')}
      isLoading={loading}
      onChange={selectUser}
      options={users}
      value={selectedUser}
      onSearch={getUsers}
      getOptionData={getOptionData}
      multiple={multiple}
    />
  );
};

SelectUser.propTypes = {
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    profile: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func.isRequired,
  specialties: PropTypes.arrayOf(PropTypes.string),
  idProfile: PropTypes.string,
  placeholder: PropTypes.string,
  getData: PropTypes.func,
  headquarter: PropTypes.string,
  headquarters: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  autoselect: PropTypes.bool,
  excludedIds: PropTypes.arrayOf(PropTypes.string),
  allowCurrentUser: PropTypes.bool,
};

SelectUser.defaultProps = {
  getData: UsersService.getAll,
};
