import { Button, Col, PageHeader, Row, Spin } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonAddKey, ApiMessage, TokenText, Tokencontainer, ColDeleteButton } from './styled';
import { Main } from '../../container/styled';
import { Cards } from '../cards/frame/cards-frame';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import useApiKey from '../../views/Api/hooks/useApiKey';
import { useNotifications } from '../../hooks/useNotifications';

const ApiCreate = () => {
  const { translate: t } = useDictionaryTranslation();
  const { dataToken, loading, generateKey, removeToken, reloadData } = useApiKey();
  const { showSuccessNotification, showConfirmNotification } = useNotifications();

  const handleClick = () => {
    generateKey();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(dataToken?.token);
    showSuccessNotification({
      title: 'success',
      description: 'textCopyToClipBoard',
    });
  };

  const handleDeleteToken = async () => {
    showConfirmNotification({
      title: t('areYouSure'),
      content: t('tokenWillBeRemoved'),
      onOk: async () => {
        await removeToken();
        reloadData()
      }
    });
  }

  return (
    <>
      <PageHeader title={t('api')} ghost />
      <Main>
        <Cards headless>
          <Spin spinning={loading}>
            <div>
              <Row justify="end">
                <ButtonAddKey onClick={() => handleClick()}>{t('apiCreate')}</ButtonAddKey>
              </Row>
              {dataToken?.token != null ? (
                <>
                  <ApiMessage>
                    {t('apiMessage')}
                  </ApiMessage>
                  <Row>
                    <Col md={22} sm={20}>
                      <Tokencontainer>
                        <Row>
                          <Col xs={23}>
                            <TokenText>{dataToken?.token.slice(0, 100)}</TokenText>
                          </Col>
                          <Col xs={1}>
                            <Button
                              onClick={handleCopy}
                              type="text"
                              style={{ color: '#aeb4c1' }}
                              icon={<CopyOutlined />}
                            />
                          </Col>
                        </Row>
                      </Tokencontainer>
                    </Col>
                    <ColDeleteButton md={2} sm={2}>
                      <Button type='danger' icon={<DeleteOutlined />} onClick={() => handleDeleteToken()} />
                    </ColDeleteButton>
                  </Row>
                </>
              ) : (
                <ApiMessage>{t('noApiKeysGenerated')}</ApiMessage>
              )}
            </div>
          </Spin>
        </Cards>
      </Main>
    </>
  );
};

export default ApiCreate;
