import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

const PaymentLinkCheckout = lazy(() => import('../../views/paymentLink/PaymentLink'));

const Pay = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:linkId?`} component={PaymentLinkCheckout} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Pay;
