import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import { UserInfoCard } from './UserInfoCard';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { profileTypes } from '../../../config/constants/userConstants';

export const UsernameDescription = ({ userType, username }) => {
  const prefix = useSelector((state) => state.auth.accountData?.prefix);
  const { translate: t } = useDictionaryTranslation();
  const textByProfile = {
    [profileTypes.TECHNICIAN.id]: {
      color: '#10AE05',
    },

    [profileTypes.INTERESTED.id]: {
      color: '#037CD1',
    },
    [profileTypes.OPERATOR.id]: {
      color:  '#5d42c4'
    }
  };

  const profileInfo = textByProfile[userType];
  const displayedUsername = `${prefix}.${username}`;

  if (profileInfo == null) return null;
  return (
    <>
      <UserInfoCard color={profileInfo.color} text={t('usernameDescription', { username: displayedUsername })} margin='30px'  />
    </>
  );
};

UsernameDescription.propTypes = {
  userType: Proptypes.string,
  username: Proptypes.string,
};
