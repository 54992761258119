import { lazy } from 'react';
import { profileTypes } from './constants/userConstants';
import { ListUsers } from '../views/Users/ListUsers';
import { UserDetails } from '../views/UserDetails/UserDetails';
import ExamType from '../views/CustomList/components/customLists/Customlist';
import CustomList from '../views/CustomList/CustomList';
import WidgetsExternal from '../views/WidgetsExternal/WidgetsExternal';
import Api from '../views/Api/Api';
import { ChatUpdateDecorator } from '../views/Chat/menuItem/chatUpdatesDecorator';

const ChatApp = lazy(() => import('../views/Chat/ChatApp2'));
const Asset = lazy(() => import('../views/Assets/Assets'));
const Account = lazy(() => import('../views/Account/components/Account'));
const AssetDetails = lazy(() => import('../views/AssetDetails/AssetDetails'));
const AssetTypes = lazy(() => import('../views/AssetTypes/assetTypes'));
const SurveyGroup = lazy(() => import('../views/Surveys/surveysGroup/components/SurveysGroup'));
const SurveyHistory = lazy(() => import('../views/Surveys/surveysHistory/components/SurveyHistory'));

const EditSurveyHistory = lazy(() =>
  import('../views/Surveys/surveysHistory/components/editSurveyHistory/EditSurveyHistory'),
);
const SurveyHistoryDetails = lazy(() =>
  import('../views/Surveys/surveysHistory/components/surveyHistoryDetails/components/SurveyHistoryDetails'),
);
const IncidentTypesOptions = lazy(() => import('../views/ServiceTypesOptions/IncidentTypesOptions'));
const TypesNote = lazy(() => import('../views/NoteTypes/NoteTypes'));
const Answersfrequents = lazy(() => import('../views/Answersfrequent/components/Answersfrequent'));
const NoteTypesItems = lazy(() => import('../views/NoteTypesItems/NoteTypesItems'));
const CancellationReassonsItems = lazy(() => import('../views/CancellationReassonsItems/CancellationReassonsItems'));
const FinalCustomerTags = lazy(() => import('../views/FinalCustomerTagsItems/FinalCustomerTagsItems'));
const FinalCustomerDetails = lazy(() => import('../views/FinalCustomerDetail/FinalCustomerDetails'));
const HeadquarterDetails = lazy(() => import('../views/HeadquartersDetail/HeadquartersDetails'));
const LegalPageSettings = lazy(() => import('../views/Legal/LegalPageSettings'));
const IntegrationWhatsappPage = lazy(() => import('../views/IntegrationWhatsapp/IntegrationWhatsappPage'));
const WhatsappCreateMessage = lazy(() => import('../views/MessengerService/whatsapp/components/WhatsappCreateMessage'));
const Whatsapp = lazy(() => import('../views/MessengerService/whatsapp/components/Whatsapp'));
const PdfSettings = lazy(() => import('../views/PdfSettings/PdfSettings'));
const EditPdf = lazy(() => import('../views/PdfSettings/tabs/personalized/editPdf/EditPdf'));

const UserDevicesLogs = lazy(() => import('../views/Users/components/userDevices/components/UserDevicesLogs'));
const UserDevices = lazy(() => import('../views/Users/components/userDevices/components/UserDevices'));

const WhatsappMessageViewDetails = lazy(() =>
  import('../views/MessengerService/whatsapp/components/WhatsappMessageViewDetails'),
);

const EditIncidentTypesNotifications = lazy(() =>
  import('../views/ServiceTypesNotificationDetails/EditIncidentTypeNotifications'),
);
const IncidentTypesNotifications = lazy(() => import('../views/ServiceTypesNotifications/IncidentTypesNotifications'));
const IncidentTypes = lazy(() => import('../views/IncidentTypes/IncidentTypes'));
const ServicesHistory = lazy(() => import('../views/ServicesHistory/ServicesHistory'));
const ServicesHistoryDetails = lazy(() => import('../views/ServicesHistoryDetails/ServicesDetail'));
const PendingsBoard = lazy(() => import('../views/PendingsBoard/PendingsBoard'));
const SelfScheduling = lazy(() => import('../views/SelfScheduling/SelfScheduling'));
const AppointmentPageSettings = lazy(() => import('../views/AppointmentsPageSettings/AppointmentPageSettings'));
const Dashboard = lazy(() => import('../views/Dashboard/Dashboard'));
const Reports = lazy(() => import('../views/Reports/Reports'));
const Modules = lazy(() => import('../views/Modules/Modules'));
const Accounts = lazy(() => import('../views/Accounts/Accounts'));
const Calendar = lazy(() => import('../views/Calendar/Calendar'));
const CustomReportsSettingsTable = lazy(() =>
  import('../views/CustomReportsSettings/components/CustomReportsSettingsTable'),
);
const CustomReportsSettingsDetail = lazy(() =>
  import('../views/CustomReportsSettings/components/CustomReportsSettingsDetail'),
);
const CustomerDetails = lazy(() => import('../views/CustomerDetails/CustomerDetails'));
const Customers = lazy(() => import('../views/Customers/Customers'));
const FinalCustomers = lazy(() => import('../views/FinalCustomers/FinalCustomers'));
const Skills = lazy(() => import('../views/Skills/Skills'));
const ListDictionary = lazy(() => import('../views/Dictionary/ListDictionary'));
const IncidentTypeDetails = lazy(() => import('../views/IncidentTypeDetails/IncidentTypeDetails'));
const Headquarters = lazy(() => import('../views/Headquarters/Headquarters'));
const Personalization = lazy(() => import('../views/Personalization/Personalization'));
const Availability = lazy(() => import('../views/Availability/Availability'));
const AwaitingList = lazy(() => import('../views/AwaitingList/AwaitingList'));
const Sequences = lazy(() => import('../views/Sequences/Sequences'));
const PaymentGateways = lazy(() => import('../views/PaymentGateways/PaymentGateways'));
// const CustomList = lazy(() => import('../views/customList/CustomList'));

export const defaultPath = '/auth/login';

export const Routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    icon: 'bar-chart-2',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: Dashboard,
  },
  {
    path: '/accounts',
    name: 'accounts',
    icon: 'bar-chart-2',
    profiles: [profileTypes.MASTER.id],
    checkPermissions: (permissions) => !permissions.manageAccounts,
    component: Accounts,
  },
  {
    path: '/calendar/:tab?',
    name: 'calendar',
    icon: 'calendar',
    profiles: [profileTypes.OPERATOR.id, profileTypes.ADMIN.id, profileTypes.TECHNICIAN.id],
    component: Calendar,
    collapseMenu: true,
  },
  {
    path: '/incident-type',
    redirect: true,
    name: 'incident-type',
    profiles: [profileTypes.ADMIN.id],
    component: IncidentTypeDetails,
  },
  {
    path: '/customers',
    name: 'customers',
    icon: 'home',
    moduleName: 'customers',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: Customers,
  },
  {
    path: '/support-chat/:chatId?',
    name: 'supportChat',
    icon: 'info',
    moduleName: 'supportChat',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: ChatApp,
    decorator: ChatUpdateDecorator,
  },
  {
    path: '/customers/:id/:tab?',
    redirect: true,
    name: 'customers',
    icon: 'shopping-bag',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: CustomerDetails,
  },
  {
    path: '/final-customer/',
    name: 'finalCustomers',
    icon: 'users',
    moduleName: 'finalCustomers',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: FinalCustomers,
  },
  {
    path: '/final-customer/:id/:tab?',
    redirect: true,
    name: 'finalCustomers',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: FinalCustomerDetails,
  },
  {
    path: '/incident-type/:id/notifications',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [profileTypes.ADMIN.id],
    component: IncidentTypesNotifications,
  },
  {
    path: '/incident-type/:incidentTypeId/edit-notifications/:notificationId?',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [profileTypes.ADMIN.id],
    component: EditIncidentTypesNotifications,
  },
  {
    path: '/incident-type/:id/options/:tab?/',
    redirect: true,
    name: 'incidentTypeNotifications',
    icon: 'mail',
    profiles: [profileTypes.ADMIN.id],
    component: IncidentTypesOptions,
  },
  {
    path: '/services-history/',
    name: 'servicesHistory',
    icon: 'list',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id, profileTypes.TECHNICIAN.id],
    component: ServicesHistory,
  },
  {
    path: '/pendings-board/',
    name: 'pendingsBoard',
    moduleName: 'pendingsBoard',
    newTab: true,
    icon: 'trello',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id, profileTypes.TECHNICIAN.id],
    component: PendingsBoard,
  },
  {
    isContainer: true,
    name: 'surveys',
    // redirect: true,
    moduleName: 'surveys',
    path: '/surveys',
    icon: 'copy',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    children: [
      {
        path: '/surveys-group',
        name: 'surveysGroup',
        profiles: [profileTypes.ADMIN.id],
        component: SurveyGroup,
      },
      {
        path: '/survey-history/',
        name: 'surveyHistory',
        profiles: [profileTypes.ADMIN.id],
        component: SurveyHistory,
      },
    ],
  },
  {
    path: '/awaiting-list/',
    name: 'awaitingList',
    icon: 'share',
    moduleName: 'awaitingList',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id, profileTypes.TECHNICIAN.id],
    component: AwaitingList,
  },
  {
    path: '/services-detail/:id/:tab?/',
    redirect: true,
    name: 'servicesHistory',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id, profileTypes.TECHNICIAN.id],
    component: ServicesHistoryDetails,
  },
  {
    path: '/headquarters/:id/:tab?',
    redirect: true,
    name: 'headquarters',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: HeadquarterDetails,
  },
  {
    path: '/incident-type-details/:incidentTypeId/:tab?',
    redirect: true,
    name: 'editIncidentType',
    profiles: [profileTypes.ADMIN.id],
    component: IncidentTypeDetails,
  },
  {
    path: '/incident-type/:incidentTypeId/self-scheduling/',
    name: 'autoScheudling',
    icon: 'calendar',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: SelfScheduling,
  },
  {
    path: '/availability/',
    name: 'availability',
    icon: 'clock',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: Availability,
    moduleName: 'advancedAvailability',
    checkPermissions: (permissions) => {
      return permissions.administrateAdvancedAvailability;
    },
  },
  {
    isContainer: true,
    name: 'config',
    path: '/settings',
    icon: 'settings',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    children: [
      {
        path: '/users',
        name: 'users',
        icon: 'person',
        profiles: [profileTypes.ADMIN.id],
        component: ListUsers,
      },
      {
        path: '/headquarters/',
        name: 'headquarters',
        icon: 'layout',
        profiles: [profileTypes.ADMIN.id],
        component: Headquarters,
      },
      {
        path: '/skills/',
        name: 'skills',
        icon: 'layout',
        profiles: [profileTypes.ADMIN.id],
        component: Skills,
      },
      {
        path: '/incident-type/',
        name: 'serviceTypes',
        icon: 'database',
        profiles: [profileTypes.ADMIN.id],
        component: IncidentTypes,
      },
      {
        path: '/appointments-landing-page/:tab?',
        defaultPath: '/appointments-landing-page/info',
        name: 'selfScheduling',
        icon: 'layout',
        profiles: [profileTypes.ADMIN.id],
        moduleName: 'selfSchedulingPage',
        component: AppointmentPageSettings,
      },

      {
        path: '/dictionary/:tab?',
        name: 'dictionary',
        icon: 'book-open',
        profiles: [profileTypes.ADMIN.id],
        component: ListDictionary,
      },
      {
        path: '/customLists',
        name: 'customLists',
        icon: '',
        profiles: [profileTypes.ADMIN.id],
        component: CustomList,
      },
      {
        path: '/note-types',
        name: 'notesTypes',
        icon: '',
        profiles: [profileTypes.ADMIN.id],
        component: TypesNote,
      },
      {
        path: '/frequent-answers',
        name: 'answersFrequent',
        icon: '',
        profiles: [profileTypes.ADMIN.id],
        component: Answersfrequents,
      },
      {
        path: '/custom-report-settings',
        name: 'customReportSettings',
        icon: '',
        profiles: [profileTypes.MASTER.id],
        component: CustomReportsSettingsTable,
      },
      {
        path: '/asset-types',
        name: 'assetTypes',
        icon: '',
        moduleName: 'asset',
        profiles: [profileTypes.ADMIN.id],
        component: AssetTypes,
      },
      {
        path: '/sequences/',
        name: 'sequences',
        profiles: [profileTypes.ADMIN.id],
        component: Sequences,
      },
      {
        path: '/pdf-settings/:tab?',
        name: 'pdfConfigText',
        icon: '',
        profiles: [profileTypes.ADMIN.id],
        component: PdfSettings,
        moduleName: 'pdfSettings',
      },
      {
        path: '/custom-pdf-format/:id?',
        name: 'pdfCreate',
        redirect: true,
        profiles: [profileTypes.ADMIN.id],
        component: EditPdf,
        moduleName: 'pdfSettings',
      },
    ],
  },
  {
    isContainer: true,
    name: 'messengerService',
    path: '/messengerService',
    icon: 'message-circle',
    moduleName: 'messaging',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    children: [
      {
        path: '/whatsapp/',
        name: 'whatsapp',
        profiles: [profileTypes.ADMIN.id],
        component: Whatsapp,
        moduleName: 'messaging',
      },
      {
        path: '/whatsapp/details/:id?',
        redirect: true,
        name: 'whatsappMessageViewDetails',
        profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
        component: WhatsappMessageViewDetails,
      },
      {
        path: '/whatsapp/cretaeMessage/',
        redirect: true,
        name: 'whatsappMessageViewDetails',
        profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
        component: WhatsappCreateMessage,
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    icon: 'file-text',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: Reports,
  },
  {
    isContainer: true,
    name: 'administration',
    path: '/administration',
    icon: 'grid',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    children: [
      {
        path: '/modules/',
        name: 'modules',
        profiles: [profileTypes.MASTER.id],
        component: Modules,
      },
      {
        path: '/widgets-external/',
        name: 'widgetsExternal',
        profiles: [profileTypes.ADMIN.id],
        component: WidgetsExternal,
      },
      {
        path: '/personalization',
        name: 'personalization',
        icon: '',
        profiles: [profileTypes.ADMIN.id],
        component: Personalization,
        checkPermissions: (permissions) => {
          return permissions.enableAccountCustomization;
        },
      },
      {
        path: '/api/',
        name: 'api',
        profiles: [profileTypes.ADMIN.id],
        component: Api,
        checkPermissions: (permissions) => {
          return permissions.enableAccountAPI;
        },
      },
      {
        path: '/integration-whatsapp/',
        name: 'integrationWhatsapp',
        profiles: [profileTypes.ADMIN.id],
        component: IntegrationWhatsappPage,
      },
      {
        path: '/legal/:tab?',
        name: 'legal',
        profiles: [profileTypes.ADMIN.id],
        component: LegalPageSettings,
      },
      {
        path: '/payment-gateways/',
        name: 'paymentGateways',
        profiles: [profileTypes.ADMIN.id],
        component: PaymentGateways,
      },
      {
        path: '/account',
        name: 'account',
        profiles: [profileTypes.ADMIN.id],
        component: Account,
      },
    ],
  },
  {
    path: '/user/:userId?/:tab?',
    name: 'userDetails',
    icon: 'person',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: UserDetails,
  },
  {
    path: '/user-devices-logs/:id?',
    name: 'userDevicesLogs',
    icon: '',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: UserDevicesLogs,
  },
  {
    path: '/user-devices/:id?',
    name: 'userDevices',
    icon: '',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: UserDevices,
  },

  {
    path: '/survey-history-edit/:surveyId?',
    name: 'EditSurveyHistory',
    redirect: true,
    icon: '',
    profiles: [profileTypes.ADMIN.id],
    component: EditSurveyHistory,
  },
  {
    path: '/survey-history-details/:surveyId?',
    name: 'surveyHistoryDetails',
    redirect: true,
    icon: '',
    profiles: [profileTypes.ADMIN.id],
    component: SurveyHistoryDetails,
  },

  {
    path: '/custom-report-settings-detail/:customReportId?',
    name: 'customReportSettingsDetail',
    redirect: true,
    profiles: [profileTypes.MASTER.id],
    component: CustomReportsSettingsDetail,
  },
  {
    path: '/customLists/:customListId?',
    name: 'ExamType',
    icon: 'person',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: ExamType,
  },
  {
    path: '/service-notes-types',
    name: 'noteTypeDetails',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: NoteTypesItems,
  },
  {
    path: '/service-cancellation-reassons',
    name: 'noteTypeDetails',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: CancellationReassonsItems,
  },
  {
    path: '/final-customer-tags',
    name: 'finalCustomerTags',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: FinalCustomerTags,
  },
  {
    path: '/asset/:id?',
    name: 'asset',
    redirect: true,
    profiles: [profileTypes.ADMIN.id],
    component: Asset,
  },
  {
    path: '/assets/:id/:tab?',
    redirect: true,
    name: 'assetsDetails',
    profiles: [profileTypes.ADMIN.id, profileTypes.OPERATOR.id],
    component: AssetDetails,
  },
];
