import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Form } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ScheduleLocks } from './ScheduleLocks';
import SchedulingTimesField from './SchedulingTimes';
import { SelectSkills } from '../../../components/skills/SelectSkills';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { CustomSwitch } from '../../SelfScheduling/components/form/editScheduleBlocks/CustomSwitch';
import { SelectHeadquarters } from '../../../components/headquarters/SelectHeadquarters';
import { theme } from '../../../config/theme/themeVariables';
import { TitleFormDefault } from '../../../container/styled';
import { solutionConstants } from '../../../config/constants/solutionsConstants';

const allowedMobileSettingsBySolutionIds = [
  solutionConstants.TAPPTUS.id, 
  solutionConstants.MIEMERGENCIA.id,
  solutionConstants.APHMED.id
];

const FormTechnician = ({ onHandleSkills, switchSkills }) => {
  const { translate: t } = useDictionaryTranslation();
  const { accountModules, solutionId } = useSelector((state) => ({
    accountModules: state.auth.accountModules,
    solutionId: state.auth.accountData.solutionId
  }));

  return (
    <>
      {allowedMobileSettingsBySolutionIds.includes(solutionId) ? (
        <>
          <Col xs={24} md={12}>
            <Form.Item name="allowToViewHistory" label={t('allowToViewHistory')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="createServicesFromMobile" label={t('createServicesFromMobile')} valuePropName="checked">
              <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
            </Form.Item>
          </Col>
        </>
      ) : null}

      <Col xs={24} md={12}>
        <Form.Item name="canChangeServiceStatus" label={t('infoServiceStatusFromMobile')} valuePropName="checked">
          <CustomSwitch checkedText={t('yes')} unCheckedText={t('no')} />
        </Form.Item>
      </Col>

      <Divider />
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('skills')}</TitleFormDefault>
        <span>{t('skillsDescription')}</span>
        <Link to="/settings/skills/">
          <span style={{ color: theme['primary-color'] }}>{t('skillsDescriptionLink')}</span>
        </Link>
        <span>{t('skillsDescriptionFinish')}</span>
        <Form.Item name="skills">
          <SelectSkills multiple />
        </Form.Item>
      </Col>
      <Col xs={24} md={20}>
        <TitleFormDefault>{t('headquarter')}</TitleFormDefault>
        <h4>{t('headquarterDescriptionTechnician')}</h4>
        <Form.Item name="headquarters">
          <SelectHeadquarters multiple />
        </Form.Item>
      </Col>

      {!accountModules.advancedAvailability ? (
        <>
          <Col xs={24} md={20}>
            <TitleFormDefault>{t('hoursavailability')}</TitleFormDefault>
            <h4>{t('hoursdescription')}</h4>
            <Form.Item name="enableSchedulingTimes" valuePropName="checked">
              <CustomSwitch
                checked={switchSkills}
                onChange={onHandleSkills}
                checkedText={t('yes')}
                unCheckedText={t('no')}
              />
            </Form.Item>
          </Col>

          {switchSkills ? (
            <Col xs={24} md={20}>
              <Form.Item name="schedulingTimes">
                <SchedulingTimesField />
              </Form.Item>
              <Divider />
              <Form.Item name="scheduleLocks">
                <ScheduleLocks />
              </Form.Item>
            </Col>
          ) : null}
        </>
      ) : null}
    </>
  );
};

FormTechnician.propTypes = {
  onHandleSkills: PropTypes.func,
  switchSkills: PropTypes.bool,
  userData: PropTypes.shape({
    schedulingTimes: PropTypes.array,
  }),
};

export default FormTechnician;
