import React, { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Auth from './auth';
import SelfScheduling from './selfScheduling';
import Account from './account';
import Pay from './pay';
import { defaultPath } from '../../config/routes';
import { DEFAULT_DOCUMENT_TITLE } from '../../config/constants/accountConstants';

const UnauthenticatedRoutes = () => {
  const routes = [
    { path: `/auth`, component: Auth },
    { path: `/self-scheduling`, component: SelfScheduling },
    { path: `/account`, component: Account },
    { path: `/pay`, component: Pay },
  ];

  useEffect(() => {
    document.title = DEFAULT_DOCUMENT_TITLE;
  }, []);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={defaultPath} />} />
          {routes.map((route) => {
            return <Route key={route.path} path={route.path} component={route.component} />;
          })}
          <Redirect to="/" />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default UnauthenticatedRoutes;
