import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_EN } from './en/translations';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: TRANSLATIONS_ES,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
  });

i18n.changeLanguage('es');

function changeLanguage(language) {
  i18n.changeLanguage(language);
}

export { changeLanguage }
