export const TRANSLATIONS_ES = {
  accept: 'Aceptar',
  accepted: 'Aceptado',
  acceptedBy: 'Aceptado por:',
  acceptedDateOverview: 'Fecha de aceptación: ',
  acceptedService: 'Aceptó {{dictionary.commonTerms.service}}',
  account: 'Cuenta',
  accountType: 'Tipo de cuenta',
  accounts: 'Cuentas',
  actions: 'Acciones',
  activateExtendForm: 'Habilitar Formulario Extendido',
  activeChats: 'Activos',
  activeGatewayTitle: 'Pasarela activa',
  add: 'Agregar',
  addBlockedDays: 'Agregar días para bloqueo',
  addItem: 'Agregar ítem',
  addListItem: 'Agregar item',
  addNotesDescription: 'Permite agregar {{dictionary.commonTerms.notePlural}}',
  addPatientList: 'Agregar {{dictionary.commonTerms.finalCustomer}} a la lista de espera',
  addService: 'Agregar',
  addVariable: '+Agregar',
  address: 'Dirección',
  addressIsRequired: 'La dirección es obligatoria',
  addressPlaceholder: 'Ingrese una dirección',
  addressRequired: 'La dirección es obligatoria',
  admin: '{{dictionary.profileNames.admin}}',
  administrateServices: 'Permite asignar {{dictionary.commonTerms.servicePlural}}',
  administrateTask: 'Permite ver el menú de Tareas',
  administrateWorkflows: 'Permite ver el menú de Flujos de trabajo',
  administration: 'Administración',
  advancedAvailability: 'Disponibilidad avanzada',
  agreement: 'Convenio',
  alert: 'Alerta',
  alertResolved: 'Respondió alerta de {{dictionary.commonTerms.service}}',
  alertResolvedBy: 'Alerta resuelta por: ',
  alertResolvedDate: 'Fecha de resolución de alerta: ',
  allPatients: 'Todos los {{dictionary.commonTerms.finalCustomerPlural}}',
  allowCreation:
    'Crearás esta {{dictionary.commonTerms.service}} sin haber asignado ningún {{dictionary.profileNames.technician}}',
  allowToViewHistory: 'Permitir ver el historial',
  allowedIncidetnTypes: 'Tipos de {{dictionary.commonTerms.servicePlural}} permitidos',
  amount: 'Monto',
  amountPaid: 'Monto pagado',
  amountRequired: 'Monto requerido',
  amountsConfiguration: 'Configuración de Montos',
  answerCreate: 'Crear Respuesta',
  answerDate: 'Fecha De Respuesta',
  answerEdit: 'Editar Respuesta',
  answers: 'Respuestas',
  answersFrequent: 'Respuestas Frecuentes',
  api: 'API',
  apiAdminOptions: 'Habilitar API (opciones de administración)',
  apiCreate: '+ Crear nuevo Api key',
  apiMessage: 'Este es tu Api key',
  apiMessageWhatsapp: 'Este es tu código o key. Usa este para colocarlo en la configuración de Meta',
  appColorMobile: 'Colores app móvil',
  appColorText:
    'Selecciona un esquema de colores, éstos serán usados en algunas secciones o botones de la aplicación móvil.',
  appMobileOptions: 'Opciones de configuración App móvil',
  appPlaceholder: 'Seleccione un formulario móvil',
  apply: 'Aplicar',
  appoinmentNoAvailable:
    'No hay {{dictionary.commonTerms.servicePlural}} disponibles bajo estos criterios de búsqueda, seleccione otras opciones y busque nuevamente.',
  appointment: '{{dictionary.commonTerms.servicePlural}}',
  appointmentAssignation: 'Asignación de {{dictionary.commonTerms.service}}',
  appointmentAssignmentMessage:
    'Está intentando asignar las siguientes {{dictionary.commonTerms.servicePlural}} al {{dictionary.commonTerms.finalCustomer}}',
  appointmentAvailable: 'Citas Disponibles',
  appointmentCancelation: 'Cancelación de {{dictionary.commonTerms.service}}',
  appointmentConfirm: '{{dictionary.commonTerms.service}} confirmada',
  appointmentNotification:
    'Me gustaria recibir notificaciones en caso de que se pueda adelantar mi cita y autorizo a que me contacten a mi numero de celular',
  appointmentOpportunitiesReport: 'Reporte de oportunidades de {{dictionary.commonTerms.servicePlural}}',
  appointmentRepeat: 'Repetir esta {{dictionary.commonTerms.service}}',
  appointmentScheduledSuccessfully: 'Tu cita se ha agendado exitosamente',
  appointmentScheduledToDate: 'para el {{date}} a las {{time}}',
  appointmentText: 'Información de {{dictionary.commonTerms.service}}',
  appointmentsOf: ' Citas de ',
  areYouSure: '¿Está seguro?',
  asset: '{{dictionary.commonTerms.asset}}',
  assetActive: 'Activo',
  assetHasOverlaps:
    '{{dictionary.commonTerms.asset}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario',
  assetInactive: 'Inactivo',
  assetNotAvailable: '{{dictionary.commonTerms.asset}} no disponible en este horario',
  assetPlaceholder: 'Seleccione {{dictionary.commonTerms.asset}}',
  assetRequired: 'Se requiere seleccionar un Activo al asignar este tipo de {{dictionary.commonTerms.servicePlural}}',
  assetTypes: 'Activos',
  assets: 'Activos',
  assetsDescription:
    'Crea listados de Activos que funcionan como bases de datos que pueden ser usados en los formularios, creación de {{dictionary.commonTerms.service}}, entre otros.',
  assetsGroup: 'Selecciona el grupo de Activos que corresponde a este tipo de Cita',
  assigmentAppoinment: 'Agendamiento de',
  assign: 'Asignar',
  assignIncidentOffSchedule: 'Asignar {{dictionary.commonTerms.service}} fuera de horario',
  assignNameToIdentifySurvey: 'Asigna un nombre para identificar este(a)  Encuesta',
  assignToFinalCustomer: 'Asignar a {{dictionary.commonTerms.finalCustomer}}',
  assignedDate: 'Fecha asignada',
  assignedDateOverview: 'Fecha de asignación: ',
  assignedService: 'Asignó {{dictionary.commonTerms.service}}',
  assignedTo: 'Asignado a',
  assignedUser: '{{dictionary.profileNames.technicianPlural}}',
  assignments: 'asignaciones',
  attachFiles: 'Adjuntar archivos',
  attachmentWillBeRemoved: 'El adjunto: "{{filename}}" será eliminado',
  attachments: 'Adjuntos',
  attendChat: 'Atender chat',
  audio: 'Audio',
  author: 'Operador',
  autoCancelService: 'Cancelar {{dictionary.commonTerms.service}} automáticamente',
  automaticNotification: 'Notificación automática',
  availability: 'Configurar Disponibilidad',
  availabilityDesccription:
    'Configura la disponibilidad de tus {{dictionary.profileNames.technicianPlural}} bajo múltiples parámetros, como {{dictionary.commonTerms.headquarter}} o tipo de {{dictionary.commonTerms.service}}.',
  availabilityTitle: 'Disponibilidad',
  available: 'Disponible',
  awaitingList: 'Lista de espera',
  awaitingListDescription:
    'Crea un listado con  {{dictionary.commonTerms.finalCustomerPlural}} que están en espera para la asignación de {{dictionary.commonTerms.servicePlural}} o adelantar asignaciones actuales.',
  backToHome: 'Volver al inicio',
  bannerSizeXY: 'Banner (tamaño {{x}} por {{y}})',
  basename: 'Nombre base',
  basenamePlaceholder: 'ingrese nombre base',
  biologicalSex: 'Sexo biológico',
  biologicalSexPlaceholder: 'Seleccione un sexo biológico',
  biologicalSexRequired: 'El sexo biológico es obligatorio',
  birthDate: 'Fecha de nacimiento',
  birthdatePlaceholder: 'Ingrese una fecha de nacimiento',
  birthdateRequired: 'La fecha y hora de nacimiento es obligatoria',
  blackTheme: 'Negro',
  blockDays: 'Bloquear días específicos',
  blockDaysdescription: 'Puedes agregar bloqueos en el agendamiento para días y horas espcecíficas',
  blockSchedule: 'Bloquear agenda',
  blockedDays: 'Días bloqueados',
  bloodType: 'Grupo sanguíneo',
  bloodTypePlaceholder: 'Seleccione un grupo sanguíneo',
  bloodTypeRequired: 'El grupo sanguíneo es obligatorio',
  blueTheme: 'Azúl',
  boardFields: 'Campos del Tablero',
  calendar: 'Calendario',
  calendarFilters: 'Filtrar calendario',
  cancel: 'Cancelar',
  cancelAvailability: 'Está intentando cancelar la disponibilidad de',
  cancelServiceTime:
    'Cancelar {{dictionary.commonTerms.service}} automáticamente en caso de sobrepasar el tiempo estimado sin ser Finalizado',
  cancelationDate: 'Fecha de cancelación: ',
  canceledByFinalCustomer: 'Canceló {{dictionary.commonTerms.service}}',
  canceledService: 'Canceló {{dictionary.commonTerms.service}}',
  cancelingAvailabilityOf: 'Está intentando cancelar la disponibilidad de',
  cancelingAvailabilityOfCalendar: 'Está intentando cancelar {{dictionary.commonTerms.servicePlural}} de',
  cancellationDate: 'Fecha',
  cancellationReasson: 'Motivo de cancelación',
  cancellationReassons: 'Motivos de cancelación',
  cancelled: 'Canceló {{dictionary.commonTerms.service}}',
  cancelledBy: 'Cancelado por: ',
  cancelledByFinalCustomer: 'Canceló {{dictionary.commonTerms.service}}',
  cancelledService: 'Permitir ofertar {{dictionary.commonTerms.servicePlural}} después de su cancelación',
  cantBeEmpty: 'No puede estar vacío',
  cantHaveSpaces: 'No puede tener espacios',
  cantSaveRecord: 'No se puede guardar el registro',
  changeStatus: 'Cambiar estado',
  changesWillNotBeSaved: 'Los cambios no serán guardados',
  characterText: 'de {{n}} caracteres',
  chatCancelled: 'Chat cancelado',
  chatClosed: 'Chat cerrado',
  chatOpen: 'Chat activo',
  chatUnassigned: 'Chat sin asignar',
  chatWillBeAssignedToYou: 'El chat será asignado a ti',
  chatWillBeFinished: 'El chat será finalizado',
  checkStatusPayment: 'Comprobar estado',
  cityPlaceholder: 'Seleccione una ciudad de residencia',
  clear: 'Limpiar',
  clickOrDragToUpload: 'De click o arrastre hacia esta área para subir un archivo',
  closedChats: 'Cerrados',
  collaborators: 'Colaboradores',
  color: 'Color',
  colorApplication: 'Color de aplicación',
  colorMark: 'Marcación de color',
  colorPlaceholder: 'Ingrese color',
  colorRequired: 'Es necesario seleccionar un color',
  compactView: 'Vista compacta',
  company: 'Compañia',
  companyAddress: 'Servicios',
  completeName: 'Nombre completo',
  completedFormMobileBy: 'Formulario móvil completado por: ',
  completedFormMobileDate: 'Fecha completado en formulario móvil: ',
  completedFormReviewBy: 'Formulario completado por: ',
  completedFormReviewDate: 'Fecha completado formulario: ',
  completedMobileForm: 'Modificó Formulario Móvil',
  completedReviewForm: 'Completó Formulario de {{dictionary.commonTerms.review}}',
  config: 'Configuración',
  configAppMobileOptions: 'Opciones de configuración de App Móvil',
  configUserMessage: 'Selecciona el tipo de usuario, ingresa la información básica y asigna una contraseña',
  configuration: 'Configuración',
  confirm: 'Confirmar',
  confirmPassword: 'Confirmar contraseña',
  confirmation: 'Confirmación',
  confirmedByFinalCustomer: 'Confirmado por {{dictionary.commonTerms.finalCustomer}}',
  confirmedByFinalCustomerDate: 'Fecha de confirmación {{dictionary.commonTerms.finalCustomer}}: ',
  confirmedService: 'Confirmó {{dictionary.commonTerms.service}}',
  confirmedServiceDescription: 'La {{dictionary.commonTerms.service}} se marcara como confirmada',
  contact: '{{dictionary.commonTerms.contact}}',
  contactAddress: 'Dirección de {{dictionary.commonTerms.contact}}',
  contactPhone: 'Telefono de {{dictionary.commonTerms.contact}}',
  contactPlaceholder: 'Seleccione {{dictionary.commonTerms.contact}}',
  contactReference: 'Referencia de {{dictionary.commonTerms.contact}}',
  contactRequired: '{{dictionary.commonTerms.contact}} es obligatorio',
  contacts: '{{dictionary.commonTerms.contactPlural}}',
  continue: 'Continuar',
  couldNotUploadFile: 'No se pudo subir el archivo: {{filename}}',
  country: 'País',
  countryPlaceholder: 'Seleccione un país',
  countryRequired: 'Debe seleccionar un país',
  create: 'Crear',
  createAccount: 'Crear Cuenta',
  createAppointment: 'Crear {{dictionary.commonTerms.servicePlural}}',
  createAssetType: 'Crear tipo de activo',
  createAvailability: 'Nueva Disponibilidad',
  createCancellationReasson: 'Crear motivo de cancelación',
  createContact: 'Crear {{dictionary.commonTerms.contact}}',
  createCustomer: 'Crear {{dictionary.commonTerms.customer}}',
  createCustomerTextOptions: 'Permitir crear y modificar {{dictionary.commonTerms.customerPlural}} desde la app móvil',
  createDiary: 'Creación de agenda',
  createDictionary: ' Crear Diccionario',
  createFinalCustomer: 'Crear {{dictionary.commonTerms.finalCustomer}}',
  createFinalCustomerTag: 'Crear Tag para {{dictionary.commonTerms.finalCustomer}}',
  createFinalCustomerTextOptions:
    'Permitir crear y modificar {{dictionary.commonTerms.finalCustomerPlural  }} desde la app móvil',
  createHeadquarter: 'Crear {{dictionary.commonTerms.headquarter}}',
  createHere: 'Puedes crearlos desde acá.',
  createKey: '+ Crear nuevo key',
  createLink: 'Crear link',
  createLinkPayment: 'Crear link de pago',
  createList: 'Crear lista',
  createListNode: 'Agregar nodo',
  createNode: 'Crear Nodo',
  createNote: 'Crear {{dictionary.commonTerms.note}}',
  createNotes: 'Crear {{dictionary.commonTerms.notePlural}}',
  createNotesType: 'Crear tipo de {{dictionary.commonTerms.notePlural}}',
  createNotification: 'Crear notificación',
  createSequence: 'Crear Secuencia',
  createService: 'Crear {{dictionary.commonTerms.service}}',
  createServicesFromMobile: 'Crear {{dictionary.commonTerms.servicePlural}} para movil',
  createServicesTextOptions: 'Permitir crear {{dictionary.commonTerms.servicePlural}} desde la app móvil',
  createSkill: 'Crear {{dictionary.commonTerms.skillsPlural}}',
  createSurveyGroup: 'Crear grupo de encuesta',
  createUser: 'Crear usuario',
  created: '¡Creado!',
  createdAt: 'Fecha Creación',
  createdBy: 'Creado por: ',
  createdPayment: 'Creado',
  createdRecords: 'Registros creados',
  createdService: 'Creó {{dictionary.commonTerms.service}}',
  creationDate: 'Fecha de creación',
  creationOfRequests: 'Creación de solicitudes',
  creationOfRequestsDescription:
    'Si habilitas esta opción, el usuario interesado podrá crear una solicitud de un {{dictionary.commonTerms.service}} , este quedará con el estado Solicitado y requerirá que un administrador u {{dictionary.profileNames.operator}} asigne un {{dictionary.profileNames.technician}}  y defina el Tipo de {{dictionary.commonTerms.service}} ',
  creationType: 'Tipo de creación',
  creationTypePlaceholder: 'Seleccione Tipo de Agendamiento',
  creator: 'Creador',
  currency: 'Moneda',
  currencyDuplicate: 'No puedes usar de nuevo esta moneda',
  currencyRequired: 'Moneda requerida',
  currentValue: 'Valor actual',
  custom: 'Personalizado',
  customFieldPlaceholder: 'Ingrese {{label}}',
  customFooterImage:
    'Usa una imagen personalizada para mostrar en el pie de página. El tamaño de la imagen debe ser de 782px x 80px (Ancho - Alto)',
  customHeaderImage:
    'Usa una imagen personalizada para mostrar en el encabezado de página. El tamaño de la imagen debe ser de 782px x 80px (Ancho - Alto)',
  customImageForReports:
    'Usa una imagen personalizada para mostrar en los reportes. El tamaño de la imagen debe ser de 360px x 135px (Ancho - Alto)',
  customList: 'Lista Personalizada',
  customLists: 'Listas Personalizadas',
  customListsPlaceholder: 'Seleccione una lista Personalizada',
  customPdfDescription:
    'Si habilitas esta opción los PDF que se generen a partir de la información de los tipos de Cita se verán acorde a la configuración del PDF personalizado',
  customPdfText: 'Usar un PDF personalizado para este tipo de {{dictionary.commonTerms.service}}',
  customReportSettings: 'Configuración de reportes',
  customTemplate: 'Seleccione una plantilla',
  customer: '{{dictionary.commonTerms.customer}}',
  customerLocation: 'Ubicación de {{dictionary.commonTerms.customer}}',
  customerName: 'Nombre de {{dictionary.commonTerms.customer}}',
  customerPhone: 'Telefono de {{dictionary.commonTerms.customer}}',
  customerPlaceholder: 'Seleccione {{dictionary.commonTerms.customer}}',
  customerRequired: '{{dictionary.commonTerms.customer}} es obligatorio',
  customers: '{{dictionary.commonTerms.customerPlural}}',
  customersDescription:
    'Si tu entidad maneja listas de {{dictionary.commonTerms.customerPlural}}, podrás seleccionar el {{dictionary.commonTerms.customer}} específico al crear la {{dictionary.commonTerms.service}}',
  customizationAdminOptions: 'Habilitar personalización (opciones de administración)',
  dashboard: 'Dashboard',
  dataPolicyMessage: 'política de datos ',
  dataTreatment: 'Tratamiento de datos',
  date: 'Fecha',
  dateAndHour: 'Fecha/Hora',
  dateSend: 'Fecha De Envío',
  day: 'Día',
  days: 'Días',
  decimalError: 'Solo se aceptan valores enteros',
  delete: 'Eliminar',
  deleteStatus: 'Borrado',
  departamentPlaceholder: 'Seleccione el departamento de residencia',
  departmentPlaceholder: 'Seleccione un departamento de residencia',
  description: 'Descripción',
  descriptionErrorNotification: 'Ya se tiene una notificación con esta misma configuración',
  descriptionOrComment: 'Descripción o comentario',
  descriptionPlaceholder: 'Ingrese una descripción',
  descriptionSelectServiceTypeClient:
    'Selecciona los tipos de {{dictionary.commonTerms.service}} para este {{dictionary.commonTerms.customer}}',
  descriptionSelfScheduling:
    'Habilita un enlace para permitir que tus {{dictionary.commonTerms.finalCustomerPlural}} realicen el agendamiento de {{dictionary.commonTerms.servicePlural}} por su cuenta',
  destination: 'Destino',
  devices: 'Dispositivos',
  diary: 'Agenda',
  dictionary: 'Diccionario',
  didUnassignIncident: 'No asignó {{dictionary.commonTerms.service}}',
  didUpdateService: 'Actualizó información de {{dictionary.commonTerms.service}}',
  doctor: 'Médico',
  documentType: 'Tipo de documento',
  documentTypePlaceholder: 'Selecione el tipo de documento',
  documentTypeRequired: 'El tipo de documento es obligatorio',
  download: 'Descargar',
  downloadBaseTemplate: 'Descargar plantilla base para importar las listas',
  downloadImportsTemplateFile: 'Descargar plantilla base para importaciones',
  downloadTemplate: 'Descargar plantilla',
  doyouAgree: '¿Está de acuerdo?',
  duration: 'Duración',
  durationPlaceholder: 'Selecciona la duracion',
  durationService:
    'La duración de la {{dictionary.commonTerms.service}} es igual para todos los {{dictionary.profileNames.technicianPlural}}',
  earrings: 'Pendientes',
  edit: 'Editar',
  editAccount: 'Editar cuenta',
  editAvailability: 'Editar Disponibilidad',
  editCancellationReasson: 'Editar motivo de cancelación',
  editContact: 'Editar {{dictionary.commonTerms.contact}}',
  editCustomer: 'Editar {{dictionary.commonTerms.customer}}',
  editDictionary: 'Editar Diccionario',
  editFinalCustomer: 'Editar {{dictionary.commonTerms.finalCustomer}}',
  editFinalCustomerTag: 'Editar Tag para {{dictionary.commonTerms.finalCustomer}}',
  editHeadquarter: 'Editar {{dictionary.commonTerms.headquarter}}',
  editIncidentType: 'Editar tipo de {{dictionary.commonTerms.service}}',
  editInfoCustomers: 'Puede modificar información de {{dictionary.commonTerms.customerPlural}}',
  editMainPage: 'Editar página principal',
  editNotesType: 'Editar tipo de notas',
  editNotification: 'Editar notificación',
  editSequence: 'Editar Secuencia',
  editService: 'Editar {{dictionary.commonTerms.service}}',
  editSkill: 'Editar {{dictionary.commonTerms.skillsPlural}}',
  editState: 'Editar Estado',
  editSurvey: 'Editar Encuesta',
  editSurveyGroup: 'Editar grupo de encuesta',
  editUser: 'Editar usuario',
  editVariable: 'Editar Variable',
  email: 'Correo electrónico',
  emailExample: 'hola@netuxtecnologia.com',
  emailMenu: 'Email',
  emailPlaceholder: 'Ingrese el correo electrónico',
  emailRequired: 'El correo electrónico es obligatorio',
  emergency: 'emergencias',
  emergencyContact: 'Contacto de emergencia:',
  emergencyWork: 'Atención en campo o',
  employee: 'Empleado',
  employeePlural: 'Empleados',
  enableAvailability: 'Habilitar Disponibilidad',
  enableCustomPdf: 'Habilitar PDF personalizado',
  enableExcelExportation: 'Habilitar botón de exportación en excel',
  enableForcedAvailabilityLocks: 'Habilitar la creación de bloqueos de disponibilidad de forma forzada',
  enableForm: 'Habilitar Formulario de revisión',
  enablePaymentGatewaysTitle: 'Habilitar pasarelas de pago',
  enableSelfscheulding: 'Habilitar esta disponibilidad para autoagendamiento web',
  enableSendEmailButton: 'Habilitar botón de envío de correo',
  enableServiceTypeForSelfScheduling: 'Habilitar tipo de {{dictionary.commonTerms.service}} para autoagendamiento',
  enableServiceTypesClient:
    '¿Quieres habilitar todos los tipos de {{dictionary.commonTerms.service}} para este {{dictionary.commonTerms.customer}}?',
  enableSupportChat: 'Habilitar chat de soporte',
  enableToAssignIncidentsOutOfSchedule:
    'Habilitar asignación de {{dictionary.commonTerms.servicePlural}} fuera del horario asignado',
  enabledUsers: 'Usuarios habilitados',
  endDate: 'Fecha de fin',
  endHour: 'Hora de fin',
  endpoint: 'Endpoint',
  endpointRequired: 'Endpoint obligatorio',
  english: 'Inglés',
  enterDateList: 'Fecha de ingreso a lista',
  enterEmail: 'Ingresar correo electrónico',
  enterNote: 'Ingresar {{dictionary.commonTerms.note}}',
  enterSession: 'Ingresar',
  enterSubject: 'Ingrese el asunto del correo electronico',
  enterYourData: 'Registra tus datos',
  enterpriseName: 'Nombre de la empresa',
  enterprisePlaceholder: 'Netux S.A.S',
  entityName: 'Nombre de entidad',
  eps: 'Eps',
  error: 'Error',
  errorCancelingService: 'Ocurrió durante la cancelación de {{dictionary.commonTerms.service}}',
  errorCantSendMessageToClosedChat: 'No se puede enviar mensajes a un chat cerrado',
  errorChatAlreadyAssignedToCurrentUser: 'El chat ya se encuentra asignado a otro usuario',
  errorChatAssignedToAnotherUser: 'El chat se encuentra asignado a otro usuario',
  errorChatNotActive: 'El chat no se encuentra activo',
  errorChatNotFound: 'Chat no encontrado',
  errorCreateMessage: 'Ocurrió un error al crear mensaje',
  errorCreatingPaymentLink: 'No se pudo generar el link de pago',
  errorCreatingRecord: 'Ocurrió un error creando el registro',
  errorDeleteSkill: 'Se ha producido un error al intentar eliminar el registro',
  errorFinishingService: 'Ocurrió durante la finalización de {{dictionary.commonTerms.service}}',
  errorRecordAlreadyExists: 'El registro ya existe',
  errorRecordNotFound: 'No se encontró el registro',
  errorRemovingRecord: 'Ocurrió un error eliminando el registro',
  errorRetrievingColors: 'Ocurrió un error cargando los colores',
  errorRetrievingRecord: 'Ocurrió un error obteniendo el registro',
  errorRetrievingRecords: 'Ocurrió un error obteniendo los registros',
  errorUpdatingRecord: 'Ocurrió un error actualizando el registro',
  errorUploadingFile: 'Ocurrió un error subiendo el archivo',
  estimatedTime: 'Tiempo estimado para cancelar el Servicio automáticamente (minutos):',
  executionDate: 'Fecha de ejecución',
  executionTime: 'Hora de ejecución',
  exportToPdf: 'Exportables en PDF',
  extendForm:
    'Con este formulario web puedes agregar campos que se deben diligenciar al momento de crear {{dictionary.commonTerms.servicePlural}}.',
  extendedWebForm: 'Formulario Extendido Web',
  extendedWebFormParagraph: 'Con este formulario web puede extender la información requerida para el autogendamiento',
  extension: '{{dictionary.commonTerms.extension}}',
  extensionPlural: '{{dictionary.commonTerms.extensionPlural}}',
  field: 'Campo',
  fieldIsRequired: 'Este campo es obligatorio',
  fieldName: 'Nombre del campo',
  fieldType: 'Tipo de campo',
  fields: 'Campos',
  fileMustBeImage: 'El archivo debe ser una imagen (jpeg o png)',
  fileSizeExceeded: 'El tamaño del archivo excede el límite de {{size}} MB',
  fileTypeNotSupported: 'Tipo de archivo no soportado',
  filesUploadingWillNotBeSaved: 'Los archivos que se están subiendo no serán guardados',
  filesWithErrorWillNotBeSaved: 'Los archivos con error no serán guardados',
  filterByServiceType: 'Filtrar por tipo de {{dictionary.commonTerms.service}}',
  filterByTechnician: 'Filtrar por {{dictionary.profileNames.technician}}',
  filterServices: 'Filtrar {{dictionary.commonTerms.servicePlural}}',
  filters: 'Filtros',
  finalCustomer: '{{dictionary.commonTerms.finalCustomer}}',
  finalCustomerAddress: 'Dirección de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerEmail: 'Correo de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerIdentification: 'Identificación de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerInfo: 'Información del {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerMobileNumber: 'Celular de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerName: 'Nombre de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerPhone: 'Teléfono de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerPlaceholder: 'Seleccione {{dictionary.commonTerms.finalCustomer}}',
  finalCustomerRequired: '{{dictionary.commonTerms.finalCustomer}} es obligatorio',
  finalCustomerTags: 'Tags de {{dictionary.commonTerms.finalCustomer}}',
  finalCustomers: '{{dictionary.commonTerms.finalCustomerPlural}}',
  finalCustomersDescription:
    'Si tu entidad emplea listado de {{dictionary.commonTerms.finalCustomerPlural}}, podrás seleccionar el {{dictionary.commonTerms.finalCustomer}} específico al crear {{dictionary.commonTerms.service}}.',
  finalDate: 'Fecha final',
  finalEdition: '¿Permitir edición después de finalizar {{dictionary.commonTerms.service}}?',
  finish: 'Finalizar',
  finishByFinalCustomerService: 'Finalización por {{dictionary.commonTerms.finalCustomer}}',
  finishChat: 'Finalizar chat',
  finishDate: 'Fecha de finalización',
  finishService: 'Finalizó {{dictionary.commonTerms.service}}',
  finishTime: 'Hora de finalización',
  finishTimeRequired: 'Hora de finalización requerida',
  finished: 'Finalizado',
  finishedAlert: 'Alerta finalizada',
  finishedBy: 'Finalizado por:',
  finishedRetries: 'Reintentos finalizados',
  forceSave: 'Forzar Guardado',
  formPlaceholder: 'Seleccione un formulario',
  frequentResponse: 'Respuesta frecuente',
  frequentResponses: 'Respuestas frecuentes',
  friday: 'Viernes',
  from: 'Desde',
  fromNAvailable: 'de {{n}}',
  fuchsiaTheme: 'Magenta',
  fullView: 'Vista completa',
  futureReminder: 'Recordatorio futuro',
  gateways: 'Pasarelas',
  generalInfo: 'Información General',
  generalOptions: 'Opciones Generales',
  generateLinkText: 'Generar enlaces específicos por {{dictionary.profileNames.technician}}',
  generatePDFText: 'Generar PDF de Atención',
  generateReport: 'Generar reporte',
  goBack: 'Regresar',
  gpsTextswitch: 'Solicitar GPS para los reportes',
  grayTheme: 'Gris',
  greenTheme: 'Verde',
  group: 'Grupo',
  groupName: 'Nombre de Grupo',
  headquarter: '{{dictionary.commonTerms.headquarter}}',
  headquarterAddress: 'Dirección de {{dictionary.commonTerms.headquarter}}',
  headquarterDefault: '{{dictionary.commonTerms.headquarter}} general',
  headquarterDescription:
    'Seleccionar {{dictionary.commonTerms.headquarter}} a la que pertenece este {{dictionary.profileNames.operator}}',
  headquarterDescriptionTechnician:
    'Seleccionar {{dictionary.commonTerms.headquarter}} a la que pertenece este {{dictionary.profileNames.technician}}',
  headquarterName: 'Nombre de {{dictionary.commonTerms.headquarter}}',
  headquarterPlaceholder: 'Selecione {{dictionary.commonTerms.headquarter}}',
  headquarters: '{{dictionary.commonTerms.headquarterPlural}}',
  headquartersTable: 'Sede',
  healthInsurance: 'EPS',
  healthInsuranceCode: 'Código de la EPS',
  healthInsurancePlaceholder: 'Seleccione una EPS',
  healthInsuranceRequired: 'La EPS es obligatoria',
  here: 'AQUÍ',
  highPriority: '{{dictionary.commonTerms.highPriority}}',
  historyServiceMobileTextSwitch:
    'Permitir visualizar el  histórico de {{dictionary.commonTerms.servicePlural}} en la app móvil',
  historyServiceTextSwitch:
    'Permitir visualizar el  histórico de {{dictionary.commonTerms.servicePlural}} en la app móvil',
  hour: 'Hora',
  hourRequired: 'La hora es requerida',
  hours: 'Horas',
  hoursavailability: 'Horarios y disponibilidad',
  hoursdescription:
    'Si habilitas horarios para el usuario, no podrás asignar {{dictionary.commonTerms.servicePlural}} en horarios que estén por fuera de sus horarios de disponibilidad',
  howOften: '¿Cada cuánto? ',
  howOftenPlaceholder: 'Selecciona cada cuánto',
  htmlEditorDescription: 'Editor en HTML',
  id: 'Id',
  idWebFormRequired: 'El Formulario es obligatorio',
  identification: 'Numero de identificación',
  identificationAlreadyExists: 'La identificación ya existe',
  identificationPlaceholder: 'Ingrese la identificación',
  identificationRequired: 'La identificación es obligatoria',
  identificationText: 'Identificación',
  identificationandScearchRequired: 'Debes realizar la búsqueda',
  identificator: 'Identificador',
  identifier: 'Identificador',
  identityKeyTestingTitle: 'Llave de identidad de pruebas',
  identityKeyTitle: 'Llave de identidad',
  idiom: 'Idioma',
  image: 'Imagen',
  imageDimensionsError: 'El tamaño de la imagen debe ser {{width}}px por {{height}}px',
  import: 'Importar',
  importCustomList: 'Importar listas personalizadas',
  importCustomListText: 'Descarga la plantilla',
  importCustomListTextModal:
    'para diligenciar la información y luego realiza la importación dando clic en el siguiente botón.',
  importFinalCustomers: 'Importar {{dictionary.commonTerms.finalCustomerPlural}}',
  importFinalCustomersResult: 'Resultado de importación de {{dictionary.commonTerms.finalCustomerPlural}}',
  importListItems: 'Importar ítems de lista',
  inContestService: 'Lanzó {{dictionary.commonTerms.service}} a concurso',
  inProductionTitle: 'En producción',
  incidentOutsideSchedule: '{{dictionary.commonTerms.service}} fuera de horario',
  incidentTypeCreate: 'Crear Tipo de {{dictionary.commonTerms.service}}',
  incidentTypeEdit: 'Editar Tipo de {{dictionary.commonTerms.service}}',
  incidentTypeText:
    'Crear tipo de {{dictionary.commonTerms.service}} o editar tipo de {{dictionary.commonTerms.service}} según el caso.',
  includeInOpportunitiesReport: 'Incluir en reporte de oportunidad de {{dictionary.commonTerms.servicePlural}}',
  info: 'Información',
  infoMessageWhatsapp:
    'Esta información no formará parte del mensaje, servirá para luego identificar en el historial qué tipo de mensaje enviaste',
  infoPatien: 'Información del {{dictionary.commonTerms.finalCustomer}}',
  infoRequired: 'Esta información es obligatoria',
  infoServiceStatus: 'Puede modificar el estado de {{dictionary.commonTerms.service}}',
  infoServiceStatusFromMobile: 'Permitir modificar el estado de {{dictionary.commonTerms.service}} en web',
  infoSwitchAvailability: 'Puede modificar la disponibilidad avanzada',
  informationCaptured: 'Información capturada: ',
  initialDate: 'Fecha inicial',
  inspectFormService: 'Con este formulario puede realizar una revisión final de su {{dictionary.commonTerms.service}}.',
  integrationWhatsapp: 'Integración Whatsapp',
  interested: '{{dictionary.profileNames.interested}}',
  isNotAValidEmail: 'No es un correo electrónico valido',
  itemNumberN: 'ítem #{{n}}',
  itemWillBeRemoved: 'Este elemento será eliminado',
  keyInvalid: 'El identifcador es invalido',
  keyLabel: 'Identificador',
  keyRequired: 'El identificador es obligatorio',
  landlinePhone: 'Teléfono fijo',
  landlinePhoneNumberPlaceholder: 'Ingrese el teléfono',
  landlinePhonePlaceholder: 'Ingrese un teléfono fijo',
  landlinePhoneRequired: 'El teléfono fijo es obligatorio',
  language: 'Lenguaje',
  languageRequired: 'El lengauaje es obligatorio',
  languageText: 'Seleccione un lenguaje',
  lastLocation: 'Ubicación final',
  lastname1: 'Primer apellido',
  lastname1Placeholder: 'Ingrese el primer apellido',
  lastname1Required: 'El primer apellido es obligatorio',
  lastname2: 'Segundo apellido',
  lastname2Placeholder: 'Ingrese el segundo apellido',
  lastname2Required: 'El segundo apellido es obligatorio',
  latitude: 'Latitud',
  legal: 'Legales',
  legalName: 'Nombre legal',
  legalNamePlaceholder: 'Ingrese el nombre legal',
  legalSize: 'Tamaño legal',
  length: 'Duración',
  lengthMaxValidation: '{{label}} debe contener máximo {{max}} caracteres',
  lengthMinValidation: '{{label}} debe contener mínimo {{min}} caracteres',
  lessThanOrEqualValidation: '{{label}} debe ser menor o igual a {{max}}',
  letterSize: 'Tamaño carta',
  lettersAndNumbersOnly: 'Solo se permiten letras y números',
  limitCharacters: 'Excediste el límite de caracteres',
  limitSelfScheduling: 'Limitar el período de autoagendamiento',
  limitSelfSchedulingDescription:
    'Limitar el período futuro habilitado en la página de autoagendamiento al seleccionar {{dictionary.commonTerms.servicePlural}}',
  link: 'Enlace',
  linkCreateHeadquarter: ' puedes crear más {{dictionary.commonTerms.headquarterPlural}}',
  linkCreateSkills: ' puedes crear más {{dictionary.commonTerms.skillsPlural}}.',
  links: 'Enlaces',
  list: 'Lista',
  loading: 'Cargando...',
  loadingItems: 'Cargando {{itemsName}}...',
  location: 'Localización',
  locks: 'Bloqueos',
  loginAction: 'Ingresar',
  loginTitle: 'Inicio de sesión',
  logo: 'logo',
  logoSizeXY: 'Logo (tamaño {{x}} por {{y}})',
  logoText:
    'Sube tu logo en formato .PNG para que aparezca  en la esquina superior izquierda al interior de la plataforma web. Tamaño obligatorio (Ancho - Alto) 400 x 80.',
  longitude: 'Longitud',
  lowPriority: '{{dictionary.commonTerms.lowPriority}}',
  mainContact: 'Nombre de {{dictionary.commonTerms.contact}} principal',
  mainContactPlaceholder: 'Ingrese el nombre de {{dictionary.commonTerms.contact}} principal',
  mainContactRequired: 'Nombre de {{dictionary.commonTerms.contact}} principal es obligatorio',
  mainPageInstructions: 'Instrucciones página principal',
  mainTitle: 'Título principal',
  map: 'Mapa',
  maxDifferenceIs1Month: 'La diferencia máxima entre las fechas debe ser de 1 mes',
  medic: 'Médico',
  medicPlural: 'Médicos',
  mediumPriority: '{{dictionary.commonTerms.mediumPriority}}',
  mentionedAssignments: 'Todas las asignaciones mencionadas quedarán ',
  message: 'Mensaje',
  messageAttempts: 'Se intentará enviar un mensaje a todos los {{dictionary.commonTerms.finalCustomerPlural}}  ({{n}})',
  messageDataTerms: 'de datos y los ',
  messageDeleteEarring: 'Se omitirá la reprogramación y se eliminará del listado de pendientes',
  messageDeletePendingIncident: 'Se omitirá la reprogramación y se eliminará del listado de pendientes',
  messageRequired: 'El mensaje es obligatorio',
  messageTermsAndConditions: 'He leído y acepto la ',
  messengerService: 'Mensajería',
  messengerServiceDescription:
    'Envía mensajes y correos  personalizados a grupos o segmentos de Pacientes directamente, o luego del cumplimiento de algunas reglas',
  messengerServiceWhatsapp: 'Mensajería: Whatsapp',
  minDateError: 'la fecha mínima es {{minDate}}',
  minRotationTime: 'El tiempo de rotación debe ser mínimo de {{n}} minutos',
  minTimeError: 'la hora mínima es {{minTime}}',
  minutes: 'Minutos',
  mobileAppColor: 'Colores de la app móvil',
  mobileAppDescription: 'si tu entidad utiliza la aplicación móvil, podrás configurar algunas opciones de los usuarios',
  mobileAppModuleDescription:
    'Si tu entidad utiliza la aplicación móvil podrás configurar algunas opciones de los usuarios',
  mobileAppModuleText: 'Configuraciones para App móvil',
  mobileForm: 'Formulario móvil',
  mobileFormLink: 'Puedes crear nuevos formularios o editarlos con los campos necesarios.',
  mobileFormRequired: 'Debe seleccionar un formulario móvil',
  mobileLogo: 'Logo Móvil',
  mobileLogoText:
    'Sube tu logo en formato .PNG para que aparezca en la barra de menú de la aplicación móvil. Tamaño obligatorio (Ancho - Alto) 270 x 100 pixeles.',
  mobilePhone: 'Celular',
  mobilePhonePlaceholder: 'Ingrese un número de teléfono celular',
  mobilePhoneRequired: 'Ingrese el celular',
  model: 'Modelo',
  modules: 'Módulos',
  monday: 'Lunes',
  monthlySurveys: 'Encuestas Mes',
  months: 'Meses',
  moveIncident: 'Mover {{dictionary.commonTerms.service}}',
  moveIncidentDescription: 'Se realizaran los siguientes cambios',
  movedToPending: 'Se moverán al listado de pendientes para no olvidar su reprogramación.',
  multipleDays: 'Múltiples días',
  nAppointments: '{{n}} {{dictionary.commonTerms.servicePlural}}',
  nAttachmentFiles: '{{n}} archivos adjuntos',
  nHours: '{{n}} horas',
  nMinutes: '{{n}} minutos',
  name: 'Nombre',
  name1: 'Primer nombre',
  name1Placeholder: 'Ingrese el primer nombre',
  name1Required: 'El primer nombre es obligatorio',
  name2: 'Segundo nombre',
  name2Placeholder: 'Ingrese el segundo nombre',
  name2Required: 'El segundo nombre es obligatorio',
  nameAccount: 'Nombre de la cuenta',
  namePlaceholder: 'Ingrese el nombre',
  nameRequired: 'El nombre es obligatorio',
  nameTheMessage: 'Asigna un nombre al mensaje',
  nationalityCountry: 'País de nacionalidad',
  nationalityCountryPlaceholder: 'Seleccione un país de nacionalidad',
  nationalityCountryRequired: 'El país de nacionalidad es obligatorio',
  newAccounts: 'Crear',
  newAppointment: 'Nueva {{dictionary.commonTerms.service}}',
  newCustomReport: 'Nuevo reporte personalizado',
  newNotification: 'Nueva notificación',
  newValue: 'Nuevo valor',
  next: 'Siguiente',
  nit: 'Nit',
  no: 'No',
  noApiKeysGenerated: 'No se han generado API keys',
  noApiKeysGeneratedWhatsapp: 'No se ha generado un key para Whatsapp',
  noAttachments: 'Sin adjunto',
  noChatSelected: 'No se ha seleccionado un chat',
  noChatsAvailable: 'No hay chats disponibles',
  noComplete: 'Asignar estado "No completado" en caso de ser activada una alerta',
  noDataTreatmentPolicy: 'No hay política de tratamiento de datos',
  noLegalTerms: 'No hay términos legales',
  noScheduleTimesAvailable:
    'El {{dictionary.profileNames.technician}} seleccionado no tiene horarios disponibles, por favor seleccione otro.',
  noSchedulesAdded: 'No se han agregado horarios',
  noValue: 'Sin valor asignado',
  notAvailable: 'No disponible',
  notCompleteService: '{{dictionary.commonTerms.service}} no completado',
  notCompletedService: 'Marcó {{dictionary.commonTerms.service}} como No completado',
  notDelivered: 'No Entregado',
  notImportedRecords: 'Registros que no fueron importados',
  notPrioritary: '{{dictionary.commonTerms.notPrioritary}}',
  note: '{{dictionary.commonTerms.note}}',
  notePlural: '{{dictionary.commonTerms.notePlural}}',
  noteTypes: 'Tipo de {{dictionary.commonTerms.note}}',
  noteTypesRequiredMessage: 'Es necesario agregar la nota',
  notes: '{{dictionary.commonTerms.notePlural}}',
  notesTypes: 'Tipos de {{dictionary.commonTerms.notePlural}}',
  notification: 'Notificación',
  notificationEvent: 'Evento',
  notificationEventRequired: 'El tipo de evento es obligatorio',
  notificationHour: 'Notificar a una hora especifica',
  notificationSent: 'Notificación enviada',
  notificationSentMessage: 'Enlace de pago enviado exitosamente',
  notificationType: 'Tipo de notificación',
  notificationTypeRequired: 'El tipo de notificación es obligatorio',
  notifications: 'Notificaciones',
  notifyTo: 'Notificar a',
  notifyToRequired: 'El destinatario es obligatorio',
  number: 'Número',
  numberAttemps: 'Numero de intentos',
  obligatory: 'Obligatoriedad',
  observation: 'Observación',
  of: 'el',
  ok: 'Ok',
  onlyNumbersValidation: '{{label}} solo permite caracteres numéricos',
  onlyOneDay: 'Un solo día',
  operator: '{{dictionary.profileNames.operator}}',
  operatorNotComplete: 'Marcó {{dictionary.commonTerms.service}} como No completado',
  optionChat: 'Opción de chat',
  optionChatDescription:
    'Selecciona si este(a) {{dictionary.commonTerms.service}} tendrá la opción de chat con el usuario {{dictionary.profileNames.interested}}',
  optional: '(Opcional)',
  optionalMobilePhone: 'Celular opcional',
  optionalMobilePhonePlaceholder: 'Ingrese un número de teléfono celular opcional',
  options: 'Opciones',
  orangeTheme: 'Naranja',
  origin: 'Origen',
  osVersion: 'Versión SO',
  other: 'Otro',
  otherOptions: 'Otras opciones',
  others: 'Otros',
  pageFooterImage: 'Imagen de pie de Página',
  pageHeaderImage: 'Imagen de Encabezado de página',
  paid: 'Pagado',
  paperType: 'Tamaño de papel',
  passwordAndConfirmationsMustBeEquals: 'La confirmación de contraseña no coincide',
  passwordDigits: 'La contraseña debe contener {{n}} dígitos',
  passwordLabel: 'Contraseña',
  passwordLength: 'La contraseña debe contener {{n}} caracteres',
  passwordLowercaseLetters: 'La contraseña debe contener {{n}} letras minúsculas',
  passwordPlaceholder: 'Escribe tu contraseña',
  passwordRequiredMessage: 'La contraseña es obligatoria',
  passwordSpecialChars: 'La contraseña debe contener {{n}} de estos caracteres: {{acceptedChars}}',
  passwordUppercaseLetters: 'La contraseña debe contener {{n}} letras mayúsculas',
  patiensHistoryAppointments:
    '{{dictionary.commonTerms.finalCustomerPlural}} que han pedido los siguientes tipos de {{dictionary.commonTerms.servicePlural}}',
  patientHasOverlaps:
    '{{dictionary.commonTerms.finalCustomer}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario',
  patientIdConfirmed: 'Identificación del Paciente Confirmado',
  patientIdRejected: 'Identificación del Paciente Rechazado',
  pay: 'Pagar',
  paymentGateways: 'Pasarelas de pago',
  paymentMethodRequired: 'Debe seleccionar una plataforma para el pago',
  paymentMethodsStepText: 'En el próximo paso se te indicarán los medios de pago',
  paymentPending: 'Pago pendiente',
  paymentPendingDescription: 'Estamos verificando el estado de su pago, este proceso puede tomar unos minutos',
  paymentRejected: 'Pago Rechazado',
  paymentStatus: 'Estado de pago',
  paymentSuccessfully: 'Pago Exitoso',
  pdfConfigDescription: 'Personaliza parcial o totalmente los documentos PDF que se generan',
  pdfConfigText: 'Configuración de PDF',
  pdfFormat: 'Formato PDF',
  pdfFormatBottomBorder: 'Borde inferior (cm)',
  pdfFormatCopyHTMLContent: 'Copiar contenido HTML',
  pdfFormatLeftBorder: 'Borde izquierdo (cm)',
  pdfFormatPlaceholder: 'Selecciona formato ',
  pdfFormatRightBorder: 'Borde derecho (cm)',
  pdfFormatTopBorder: 'Borde superior (cm)',
  pdfFormatsCustom: 'Personalizados',
  pdfFormatsStandard: 'Estándar',
  pdfReports: 'Reportes PDF',
  pdfSettings: 'Configuración de PDF',
  pdfTheme: 'Tema para el PDF',
  pdfThemeDescription: 'Generar PDF para este tipo de {{dictionary.commonTerms.service}}',
  pending: 'Pendiente',
  pendingAlert: 'Alerta pendiente',
  pendingAssignments: 'Pendientes por asignar ',
  pendings: 'Pendientes',
  pendingsBoard: 'Tablero de pendientes',
  pendingsBoardDescription: 'Visualiza un tablero con la lista de {{dictionary.commonTerms.servicePlural}} pendientes',
  permission: 'Permissos',
  permissionDenied: 'No tiene permiso para esta operación',
  personInCharge: 'Responsable',
  personalName: 'Nombre del personal',
  personalQuestion: '3.¿Cómo denominas a tu personal?',
  personalization: 'Personalización',
  petition: 'Solicitó {{dictionary.commonTerms.service}}',
  petitionBy: 'Petición hecha por: ',
  petitionDate: 'Fecha de petición: ',
  petitionService: 'Solicitó {{dictionary.commonTerms.service}} ',
  phoneNumber: 'Teléfono',
  phoneNumberPlaceholder: 'Ingrese el teléfono',
  phoneNumberRequired: 'El teléfono es obligatorio',
  plural: 'Plural',
  pluralPlaceholder: 'Ingrese Plural',
  powerBy: 'Powered By',
  prefix: 'Prefijo',
  prefixNotAvailable: 'Prefijo no disponible',
  prefixPlaceholder: 'netux',
  prefixRequired: 'El prefijo es obligatorio',
  prefixText: 'Escribe un prefijo o identificador',
  preview: 'Preview',
  previous: 'Atrás',
  previousValue: 'Valor anterior',
  priority: '{{dictionary.commonTerms.priority}}',
  priorityPlaceholder: 'selecciona la prioridad',
  priorityRequired: 'Prioridad requerida',
  privateId: 'ID',
  programmed: 'Programado',
  programming: 'Programación',
  programmingDescription:
    'Programa {{dictionary.commonTerms.service}} con antelación para que se repitan múltiples veces en el futuro.',
  publicName: 'Nombre público',
  publicNameRequired: 'El nombre público es obligatorio',
  purpleTheme: 'Morado',
  reCaptchaError: 'Error en la verificación, por favor intente de nuevo',
  recordWillBeRemoved:
    'El registro: {{recordDescription}} será eliminado completamente. Esta acción no se puede deshacer',
  redTheme: 'Rojo',
  reducedView: 'Vista reducida',
  reference: 'Referencia',
  referencePlaceholder: 'Ingrese la referencia',
  referenceRequired: 'La referencia es obligatoria',
  regime: 'Régimen',
  regimePlaceholder: 'Seleccione un régimen',
  regimeRequired: 'El régimen es obligatorio',
  registerDate: 'Fecha de registro',
  rejected: 'Rechazado',
  rejectedBy: 'Rechazado Por: ',
  rejectedDate: 'Fecha de rechazo: ',
  rejectedService: 'Rechazó {{dictionary.commonTerms.service}}',
  remainingAssignments: 'asignaciones restantes quedarán',
  remindWhen: 'Recordar cuando',
  remindWhenRequired: 'El cuando recordar es obligatorio',
  reminder: 'Recordatorio',
  remove: 'Eliminar',
  removeDate: 'se eliminará la fecha {{-from}} - {{-to}}',
  removeUserExist: 'No es posible eliminar el {{dictionary.commonTerms.finalCustomer}}',
  removeUserExistDescription: 'Debido a que tiene citas agendadas',
  removed: '¡Eliminado!',
  repeatSurvey: 'Repetir Encuesta',
  repeatTimes: '¿Cuántas veces se repetirá?',
  repeatTimesPlaceholder: 'Selecciona cantidad de veces',
  report: 'Reporte',
  reportFieldsConfig: 'Configurar los campos del reporte',
  reportLogo: 'Logo del reporte',
  reportName: 'Nombre del reporte',
  reportNameRequired: 'Nombre del reporte obligatorio',
  reports: 'Reportes',
  reprogramCount: 'Se reprogramarán las',
  reprogrammedCount: 'Se reprogramarán ',
  reschedule: 'Reprogramar',
  rescheduleCount: 'Se reprogramarán las',
  rescheduleCountTranslation: 'Serán reprogramados',
  reschedulelater: 'Reprogramar luego',
  reserveAnotherAppointment: 'Agendar otra {{dictionary.commonTerms.service}}',
  reserveAppointment: 'Reservar {{dictionary.commonTerms.service}}',
  residenceCity: 'Ciudad de residencia',
  residenceCityRequired: 'La ciudad de residencia es obligatoria',
  residenceDepartment: 'Departamento de residencia',
  residenceDepartmentPlaceholder: 'Seleccione un departamento de residencia',
  residenceDepartmentRequired: 'La departamento de residencia es obligatoria',
  resolutionText:
    'Si hubieras podido escoger una fecha diferente a la que seleccionaste para esta cita, por favor indícanos cuál (Resolución 1552 de 2023).',
  resource: 'Recurso',
  resourceDescription:
    'Seleccionar {{dictionary.profileNames.technicianPlural}} que puede gestionar este {{dictionary.profileNames.operator}}',
  resourceManagement: 'Gestión de {{dictionary.profileNames.technicianPlural}}',
  resourceName: 'Nombre de {{dictionary.profileNames.technician}}',
  resourcePlaceholder: 'Selecciona {{dictionary.profileNames.technician}}',
  resources: 'Recursos',
  response: 'Respuesta',
  responseDate: 'Fecha De Respuesta',
  responses: 'Respuestas',
  retried: 'Reintentado',
  retry: 'Reintentar',
  retryNumber: 'Numero de reintentos',
  retryPeriod: 'Periodo de reintentos',
  retrySendingAfter24Hours: 'Se debe reintentar el envío luego de 24 horas sin respuesta',
  return: 'Volver',
  review: '{{dictionary.commonTerms.review}}',
  reviewPlural: '{{dictionary.commonTerms.reviewPlural}}',
  reviewYourAvailability: 'Revisa tu disponibilidad, se enviará la confirmación a tu correo electrónico',
  rotationTime: 'Tiempo de rotación',
  rotationTimeMinutes: 'Tiempo de rotación (minutos)',
  rotationTimeRequired: 'El tiempo de rotación es obligatorio',
  rowInFile: 'Fila en el archivo',
  saturday: 'Sábado',
  save: 'Guardar',
  scheduleLock: 'Bloqueo de horario',
  scheduleTimeNotAvailable: 'Horario no disponible',
  scheduled: 'Programado',
  scheduledAppointments: 'Las siguientes {{dictionary.commonTerms.servicePlural}} están agendadas en ese horario:',
  search: 'Buscar',
  searchAppointment: 'Buscar {{dictionary.commonTerms.service}}',
  searchAssignment: 'Buscar asignación',
  searchChat: 'Buscar chat',
  searchCustomerPlaceholder: 'Ingrese nombre o identificación',
  searchRequired: 'Debes realizar la búsqueda',
  searchSkillsPlaceholder: 'Ingrese habilidad',
  searchSpecialist: 'Buscar Especialista',
  secondaryEmail: 'Correo electrónico secundario',
  secondaryEmailPlaceholder: 'Ingrese el correo electrónico secundario',
  secretKeyTestingTitle: 'Llave secreta de pruebas',
  secretKeyTitle: 'Llave secreta',
  seeAllServices: 'Permite visualizar todos los tipos de {{dictionary.commonTerms.servicePlural}}',
  seeAllTask: 'Permite ver Tareas asignadas a cualquier usuario Operador',
  seeAllWorkflows: ' Permite ver Flujos de trabajo asignados a cualquier usuario Operador',
  selectAppointmentDate: 'Seleccione un día para su cita',
  selectAppointmentTime: 'Seleccione una hora',
  selectAppointmentsTimes: 'Seleccione las horas para la cita',
  selectAsset: 'Seleccione {{dictionary.commonTerms.asset}}',
  selectAtLeastOneDay: 'Debe seleccionar al menos un día',
  selectCancellationReasson: 'Seleccione el motivo de cancelación para la cita',
  selectColorForExportableFormat: 'Seleccione un color para ser usado en el formato exportable',
  selectEpsPlaceHolder: 'Seleccione eps',
  selectFieldType: 'Seleccione el tipo de campo',
  selectFieldsToDisplay: 'Seleccione los campos que desea visualizar en los reportes',
  selectFile: 'Seleccionar archivo',
  selectForm: 'Selecciona el formulario que se diligenciará desde la aplicación móvil.',
  selectFormat: 'Selecciona un formato',
  selectFrequentResponse: 'Seleccionar respuesta frecuente',
  selectGroup: 'Seleccione un grupo',
  selectHeadquarter:
    'Selecciona las {{dictionary.commonTerms.headquarterPlural}} que aplican para este tipo de {{dictionary.commonTerms.service}}. También',
  selectMessageSendingStart: 'Selecciona el momento en que empezará el envío de los mensajes',
  selectNewStatus: 'Seleccione el nuevo estado para este {{dictionary.commonTerms.service}}',
  selectPatienText:
    'Selecciona los campos específicos del {{dictionary.commonTerms.finalCustomer}} que éste deberá diligenciar al momento de autoagendarse',
  selectPaymentProviders: 'Seleccione una opción para realizar el pago: ',
  selectPreferredAction: 'Seleccione la acción que prefiera aplicar',
  selectRecipientForMessages: 'Selecciona a quién enviarás los mensajes',
  selectSequence: 'Seleccione una secuencia',
  selectSkills:
    'Selecciona las {{dictionary.commonTerms.skillsPlural}} que aplican para este tipo de {{dictionary.commonTerms.service}}. También',
  selectSkillsBrief: 'Selecciona las {{dictionary.commonTerms.skillsPlural}}',
  selectSkillsCanSeeReports: 'Selecciona las {{dictionary.commonTerms.skillsPlural}} que tendrán acceso a este reporte',
  selectSolution: 'Seleccione el tipo de solución',
  selectStatus: 'Seleccione un estado',
  selectTechnicianAndDate:
    'Seleccione un {{dictionary.profileNames.technician}} y una fecha para buscar la posibilidad de reasignación:',
  selectTemplate: 'Seleccione template',
  selectTheAppointment: 'Elige la cita',
  selectTypeUserPlaceholder: 'Selecciona el tipo de usuario',
  selectWebForm: 'Seleccione formulario',
  selectWhoYou: 'Selecciona a quién le enviarás tu mensaje',
  selfScheduled: 'Autoagendado',
  selfScheduling: 'Autoagendamiento',
  selfSchedulingAccessLink: 'Enlace de acceso',
  selfSchedulingInstructions: 'Instrucciones para este tipo de {{dictionary.commonTerms.service}}',
  selfSchedulingLengthInstructions:
    'Defina cuanto debe durar, tenga en cuenta que este tiempo determinará las horas a mostrar en la página de autoagendamiento',
  selfSchedulingPublicNameInstructions: 'Este es el nombre que veran los usuarios en la página de autoagendamiento',
  send: 'Enviar',
  sendEmail: 'Enviar correo',
  sent: 'Enviado',
  sentMessages: 'Enviar mensajes',
  sequence: 'Secuencia',
  sequenceWillBeRemoved: 'La secuencia será eliminada',
  sequences: 'Secuencias',
  service: '{{dictionary.commonTerms.service}}',
  serviceAssigned: 'Asignación de {{dictionary.commonTerms.service}}',
  serviceAssignment: 'Asignación de {{dictionary.commonTerms.servicePlural}}',
  serviceCanceled: 'Cancelación de {{dictionary.commonTerms.service}}',
  serviceConfirmation: 'Confirmación de {{dictionary.commonTerms.service}}',
  serviceConfrimation: 'confirmación de {{dictionary.commonTerms.service}}',
  serviceDate: 'Fecha',
  serviceDatePlaceholder: 'Ingrese la fecha',
  serviceDateRequired: 'La fecha es obligatoria',
  serviceDescription: 'Descripción',
  serviceDescriptionPlaceholder: 'Ingrese descripción',
  serviceDescriptionRequired: 'Descripción es obligatoria',
  serviceModified: 'Modificación de {{dictionary.commonTerms.service}}',
  serviceName: 'Nombre de {{dictionary.commonTerms.service}}',
  serviceNamePlaceholder: 'Ingrese nombre {{dictionary.commonTerms.service}}',
  serviceNameRequired: 'Nombre {{dictionary.commonTerms.service}} es obligatorio',
  serviceTime: 'Hora',
  serviceTimePlaceholder: 'Ingrese la hora',
  serviceTimeRequired: 'La hora es obligatoria',
  serviceType: 'Tipo de {{dictionary.commonTerms.service}}',
  serviceTypeNotAssignedToHeadquarter:
    'El tipo de {{dictionary.commonTerms.service}} no está asignado a {{dictionary.commonTerms.headquarter}}',
  serviceTypeNotAssignedToX: 'El tipo de {{dictionary.commonTerms.service}} no está asignado a {{x}}',
  serviceTypePlaceholder: 'Seleccione un tipo de {{dictionary.commonTerms.service}}',
  serviceTypeRequired: 'Tipo de {{dictionary.commonTerms.service}} es obligatorio',
  serviceTypeText: 'Nuevo tipo de {{dictionary.commonTerms.service}}',
  serviceTypes: 'Tipo de {{dictionary.commonTerms.servicePlural}}',
  serviceWillBeCanceled: '{{dictionary.commonTerms.service}} se cancelará',
  serviceWillBeFinished: '{{dictionary.commonTerms.service}} se finalizará',
  services: '{{dictionary.commonTerms.servicePlural}}',
  servicesByType: '{{dictionary.commonTerms.servicePlural}} por tipo',
  servicesCanceled: '{{dictionary.commonTerms.servicePlural}} que se cancelaron',
  servicesConfirmed: '{{dictionary.commonTerms.servicePlural}} que se confirmaron',
  servicesCreated: '{{dictionary.commonTerms.servicePlural}} que se crearon',
  servicesHistory: 'Historial de {{dictionary.commonTerms.servicePlural}}',
  servicesLanding: 'Landing de {{dictionary.commonTerms.servicePlural}}',
  servicesPerDay: '{{dictionary.commonTerms.servicePlural}} por día',
  servicesSelfScheduled: '{{dictionary.commonTerms.servicePlural}} que se autoagendaron',
  servicesTypesAssigned:
    'Seleccione los tipos de {{dictionary.commonTerms.servicePlural}} con permiso de visualización',
  shippingDate: 'Fecha de envio',
  shortDescription: 'Descripción corta',
  signOut: 'Cerrar sesión',
  signature: 'Firma',
  singular: 'Singular',
  singularPlaceholder: 'Ingrese singular',
  siteName: 'Escribe el nombre que aparecerá en la pestaña del navegador',
  siteTitle: 'Titulo del sitio',
  skillPlaceholder: 'Selecciona las habilidades',
  skillWillBeRemoved: 'Este registro será eliminado completamente.',
  skills: '{{dictionary.commonTerms.skillsPlural}}',
  skillsDescription: ' Selecciona {{dictionary.commonTerms.skillsPlural}} para este Recurso. ',
  skillsDescriptionFinish: 'y asociarlas a los tipos de {{dictionary.commonTerms.service}}',
  skillsDescriptionLink: 'También puedes crear más {{dictionary.commonTerms.skillsPlural}} ',
  skillsForCustomPdf: '{{dictionary.commonTerms.skillsPlural}} con acceso a este PDF',
  skillsRequired: 'Debe seleccionar al menos una {{dictionary.commonTerms.skills}}',
  solutionRequired: 'Es necesario seleccionar un tipo solución',
  solutionType: 'Tipo de solución',
  spanish: 'Español',
  specialist: 'Especialista',
  specialistPlural: 'Especialistas',
  specificEmail: 'Correo específico',
  startDate: 'Fecha de inicio',
  startHour: 'Hora de inicio',
  startService: 'Inició {{dictionary.commonTerms.service}}',
  startTime: 'Hora de inicio',
  startTimeRequired: 'Hora de inicio requerida',
  startTimeShouldBeGreater: 'La hora de finalización debe ser mayor',
  startedService: 'Inició {{dictionary.commonTerms.service}}',
  states: 'Estados',
  status: 'Estado',
  statusChangeNotAllowed: 'Cambio de estado no permitido',
  statusRequired: 'Estado requerido',
  stepNameRequired: 'El nombre del paso es obligatorio',
  steps: 'Pasos',
  stepsRequired: 'Debes agregar mínimo un paso',
  subject: 'Asunto',
  subjectRequired: 'El asunto es obligatorio',
  succesSendMessages:
    'Se intentará enviar un mensaje a todos los {{dictionary.commonTerms.finalCustomerPlural}}. Tenga en cuenta que si alguno de ellos no tiene un número válido registrado, no recibirá la notificación.',
  success: 'Éxito',
  successCancelingService: 'Cancelación de {{dictionary.commonTerms.service}} exitosa',
  successCreateToken: 'Token generado con éxito',
  successCreatingCancellation: 'Cancelación creada con éxito',
  successCreatingRecord: 'Registro creado con éxito',
  successDeleteSkill: 'Registro eliminado con éxito',
  successFinishingService: 'Finalización de {{dictionary.commonTerms.service}} exitosa',
  successRemoveService: '{{dictionary.commonTerms.service}} eliminada con éxito',
  successRemovingRecord: 'Registro eliminado con exito',
  successUpdatingRecord: 'Registro actualizado con exito',
  successUploadingFile: 'Archivo subido con éxito',
  summary: 'Resumen',
  sunday: 'Domingo',
  supportChat: 'Chat de soporte',
  supportChatDescription:
    'Habilita el chat de soporte para que los {{dictionary.commonTerms.finalCustomerPlural}} puedan comunicarse con el equipo de soporte',
  surveyHistory: 'Histórico de Encuestas',
  surveyInfoNotPartOfMessage:
    'Esta información no será parte del mensaje, servirá para identificar Encuestas luego en el histórico',
  surveys: 'Encuestas',
  surveysDescription: 'Realiza el envío de Encuestas por medio de Whatsapp a tus Clientes finales',
  surveysGroup: 'Grupo de Encuestas',
  synchronizedBy: 'Sincronizados por: ',
  synchronizedData: 'Sincronizó información parcialmente',
  synchronizedDate: 'Fecha de sincronización: ',
  synchronizedService: 'Sincronizó {{dictionary.commonTerms.service}}',
  tagsPlaceholder: 'Ingresa los Tags del {{dictionary.commonTerms.finalCustomer}}',
  task: 'Tareas',
  technician: '{{dictionary.profileNames.technician}}',
  technicianPlaceholder: 'Seleccione {{dictionary.profileNames.technician}}',
  technicianPlural: '{{dictionary.profileNames.technicianPlural}}',
  technicianRequired: '{{dictionary.profileNames.technician}} es obligatorio',
  technicianScheduleOverlaps:
    '{{dictionary.profileNames.technician}} ya tiene otra asignación que se cruza con la hora que desea asignar',
  techniciansMap: 'Mapa de Recursos',
  techniciansMapDescription: 'Visualiza en un mapa las últimas ubicaciones por los usuarios móviles',
  templateRequired: 'La plantilla es obligatoria',
  templates: 'Plantillas',
  terms: 'Términos',
  termsAndConditionsMessage: 'términos y condiciones',
  termsAndConditionsTitle: 'Términos Y Condiciones',
  textAccountuser:
    'Para ingresar a tu cuenta puedes usar tu nombre de usuario {{prefix}}.{{username}} y la contraseña que ingresaste',
  textCopyToClipBoard: 'El texto se ha copiado al portapapeles',
  textGenerate: 'Se ha generado una nueva api key',
  the: ' de',
  theFollowingAppointments: 'Las siguientes asignaciones están agendadas en ese horario:',
  theFollowingAppointmentsCalendar: 'En este horario se encontraron las siguientes asignaciones:',
  thereAreActiveAndUnassignedChats: 'Hay chats activos y sin asignar',
  thereAreActiveChats: 'Hay chats activos',
  thereAreServicesAssignedWarning: 'Hay asignaciones agendadas para este horario',
  thereAreUnassignedChats: 'Hay chats sin asignar',
  thisFieldIsRequired: 'Este campo es obligatorio',
  thisRecordWillBeRemoved: 'Este registro será eliminado',
  thursday: 'Jueves',
  time: 'Tiempo',
  timeEstimated: 'Tiempo estimado para cancelar {{dictionary.commonTerms.service}}',
  timeExecution: 'Tiempo de ejecución en minutos',
  timeFormat: 'Formato del horario',
  timeUnit: 'Unidad de tiempo',
  timeUnitPlaceholder: 'Días, semanas, meses, años',
  timeUnitPlaceholderPleace: 'Selecciona unidad de tiempo',
  timeUnitRequired: 'Unidad de tiempo',
  timeZone: 'Zona horaria',
  timeZoneExample: '(UTC-05:00) Bogota, Lima, Quito',
  timeZoneText: 'Seleccione su zona horaria',
  timezoneRequired: 'La zona horaria es obligatoria',
  titleCardselfScheduling: 'Página de autoagendamiento',
  titleErrorNotification: 'No es posible crear esta notificación',
  tittleIntegrationwhatsapp: 'Integración Whatsapp',
  to: 'a',
  tokenMetaMessage: 'Ingresa el token permanente generado por Meta',
  tokenWillBeRemoved: 'El token será eliminado está acción es irreversible',
  topic: '{{dictionary.commonTerms.topic}}',
  topicPlural: '{{dictionary.commonTerms.topicPlural}}',
  totalitems: 'Total de items',
  trialPeriod: 'Periodo de intentos',
  tuesday: 'Martes',
  typeOfExam: 'Tipo de examen',
  typeUsertext: 'Selecciona el tipo de usuario, ingresa la Información básica y asigna una contraseña',
  unassignedChats: 'Sin asignar',
  unconfirmedAppointment: '{{dictionary.commonTerms.service}} no confirmada',
  until: 'Hasta',
  updateInProcess: 'Actualización en proceso',
  updateList: 'Editar lista',
  updateListNode: 'Actualizar nodo',
  updateMobileForm: 'Actualizó información de {{dictionary.commonTerms.service}}',
  updatePasswordText: 'Cambiar contraseña',
  updatePendingFinish: 'En revisión',
  updated: '¡Actualizado!',
  updatedBy: 'Actualizado por: ',
  updatedDate: 'Fecha de actualización: ',
  updatedFields: 'Campos actualizados',
  updatedMobileFormDate: 'Fecha de formulario móvil completado: ',
  updatedMobileFormdBy: 'Formulario móvil actualizado por: ',
  updatedRecords: 'Registros actualizados',
  updatedReviewForm: 'Modificó Formulario de {{dictionary.commonTerms.review}}',
  uploadImportsTemplateMessage: 'Subir plantilla diligenciada',
  uploadTemplate: 'Subir plantilla',
  user: 'Usuario',
  userAdmin: 'Usuario administrador',
  userAdminPlaceholder: 'admin',
  userAdminRequired: 'Usuario administrador es obligatorio',
  userCannotPerformService:
    '{{dictionary.profileNames.technician}} no puede realizar este tipo de {{dictionary.commonTerms.service}}',
  userDevices: 'Dispositivos del usuario',
  userDevicesLogs: 'Logs de ubicación del usuario',
  userHasOverlaps:
    '{{dictionary.profileNames.technician}} tiene {{dictionary.commonTerms.servicePlural}} que se cruzan en el mismo horario',
  userNotAssignedToHeadquarter:
    '{{dictionary.profileNames.technician}} no está asignado a {{dictionary.commonTerms.headquarter}}',
  userNotAssignedToX: '{{dictionary.profileNames.technician}} no está asignado a {{x}}',
  userNotAvailable: '{{dictionary.profileNames.technician}} no está disponible en el horario seleccionado',
  userPlaceholder: 'Selecciona el usuario',
  userType: 'Tipo de Usuario',
  userTypeConfigBase: 'Tipo de usuario y configuración base',
  userTypeDescriptiontitle: 'Ten en cuenta que',
  userTypeInterestedDescription:
    ' El usuario {{dictionary.profileNames.interested}}  utiliza la plataforma web y solo se le permite la visualización de reportes',
  userTypeOperatorDescription:
    'El usuario {{dictionary.profileNames.operator}} utiliza la plataforma web y se enfoca en el agendamiento y seguimiento de Citas',
  userTypeRequired: 'tipo de usuario es obligatorio',
  userTypeTechnicianDescription:
    'El usuario {{dictionary.profileNames.technician}} se encarga de realizar/atender {{dictionary.commonTerms.servicePlural}} Dependiendo la configuración de tu cuenta puede utilizar la aplicación web o la aplicación móvil',
  userWillBeDisabled: 'El usuario será deshabilitado y no podrá ingresar a la plataforma',
  username: 'Nombre de usuario',
  usernameDescription: 'El usuario deberá usar {{username}} para ingresar a la aplicación',
  usernameLabel: 'Usuario o correo electrónico',
  usernamePlaceholder: 'Escribe tu usuario o correo electrónico',
  usernameRequired: 'Debes ingresar un nombre de usuario',
  usernameRequiredMessage: 'Debes ingresar un usuario o correo electrónico',
  users: 'Usuarios',
  validationRemindWhen: 'El valor no puede ser menor o igual a 0',
  value: 'Valor',
  valueLabel: 'Valor',
  valueRequired: 'El valor es obligatorio',
  valueToPay: 'Valor a pagar: ',
  variableCreate: 'Crear variable',
  variableEdit: 'Editar variable',
  variables: 'Variables',
  variablesDescription: 'Habilitar variables a este tipo de atención',
  verifyAssignations: 'Verificar asignaciones',
  version: 'Version',
  video: 'Video',
  viewAccounts: 'Ver cuentas',
  viewCustomers: 'Visualizar {{dictionary.commonTerms.customerPlural}}',
  viewCustomersText:
    'El usuario podrá ver el listado de {{dictionary.commonTerms.customerPlural}} y seleccionar uno de acuerdo con su prefetencia. Selecciona NO únicamente si no tienes creado ningún {{dictionary.commonTerms.customer}}.',
  viewDetails: 'Ver detalles',
  viewMore: 'Ver más',
  viewSurvey: 'Visualizar Encuesta',
  viewUpdatedFields: 'Ver campos actualizados',
  viewUsers: 'Visualizar {{dictionary.profileNames.technicianPlural}}',
  viewUsersText:
    'El usuario podrá ver el listado de {{dictionary.profileNames.technicianPlural}} y seleccionar de acuerdo a su preferencia. Si seleccionas NO el usuario no verá el listado de {{dictionary.profileNames.technicianPlural}} y en ese caso se asignará de forma aleatoria uno que esté disponible.',
  visibleHistoryFields: 'Campos visibles en Histórico',
  waitingForResponse: 'Esperando Respuesta',
  warning: 'Advertencia',
  webhookMessage: 'En Meta necesitas también ingresar el webhook. Recuerda que es el siguiente:',
  wednesday: 'Miércoles',
  week: 'Semana',
  weeks: 'Semanas',
  welcomeText: '¡Bienvenido! Diligencia la siguiente información para crear tu cuenta',
  whatsapp: 'Whatsapp',
  whatsappScheulding: 'Permitir agendamiento por Whatsapp',
  widgetsExternal: 'Widgets externos',
  willBeAssigned: 'Se asignarán ',
  withoutAlert: 'Sin alerta',
  workField: 'Trabajo en campo',
  workflow: 'Flujo de trabajo',
  writeAMessage: 'Escribe un mensaje',
  xNotAssignedToY: '{{x}} no está asignado a {{y}}',
  years: 'Años',
  yes: 'Si',
  yourAppointmentWillBeConfirmed: 'Tu cita será confirmada',
};
