import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider, Spin } from 'antd';
import locale from 'antd/es/locale/es_ES';
import moment from 'moment';
import localization from 'moment/locale/es';
import store from './redux/store';
import './static/css/style.css';
import config from './config/config';
import './translations/i18n';
import { checkAuth } from './redux/authentication/actionCreator';
import UnauthenticatedRoutes from './routes/unauthenticated/unauthenticated';
import AuthenticatedApp from './routes/admin/authenticated';
import 'antd/dist/antd.less';

moment.updateLocale('es', localization);

const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, checkLogin } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      checkLogin: state.auth.checkLogin,
    };
  });

  useEffect(() => {
    console.log('ProviderConfig', checkLogin);
    if (checkLogin) dispatch(checkAuth());
  }, [checkLogin, dispatch]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={locale}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        {checkLogin ? (
          <div className="spin">
            <Spin />
          </div>
        ) : !isLoggedIn ? (
          <UnauthenticatedRoutes />
        ) : (
          <AuthenticatedApp />
        )}
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
