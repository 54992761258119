import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { CredentialsApiService } from '../../../services/credentialsApiService';
import { useNotifications } from '../../../hooks/useNotifications';

const useApiKey = () => {
  const [loading, setLoading] = useState(false);
  const [dataToken, setDataToken] = useState();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const getToken = useCallback(async () => {
    setLoading(true);
    try {
      const data = await CredentialsApiService.getCredential();
      const token = data.tokens[0];
      setDataToken({
        token: token?.token,
        date: moment(token?.expiresIn).format('DD-MM-YYYY'),
      });
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }, [showErrorNotification, showErrorNotification]);

  const generateKey = useCallback(async () => {
    setLoading(true);
    try {
      const data = await CredentialsApiService.generateToken();
      setDataToken({
        token: data.apiToken,
        date:moment(data.date).format('DD-MM-YYYY')
      });
      showSuccessNotification({
        title: 'success',
        description: 'textGenerate',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorRetrievingRecord',
      });
    } finally {
      setLoading(false);
    }
  }, [showSuccessNotification, showErrorNotification]);

  const removeToken = useCallback(async () => {
    setLoading(true);
    try {
      await CredentialsApiService.remokeToken()
      setDataToken(null)
      showSuccessNotification({
        title: 'success',
        description: 'successDeleteSkill',
      });
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorDeleteSkill',
      });
    } finally {
      setLoading(false)
    }
  }, [showSuccessNotification, showErrorNotification]
  );

  const reloadData = useCallback(() => {
    getToken();
  },[ getToken])

  useEffect(() => {
    reloadData();
  }, [reloadData]);

  return {
    loading,
    dataToken,
    generateKey,
    removeToken,
    reloadData,
  };
};

export default useApiKey;
