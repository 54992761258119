import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class CredentialsApiService {
  static async getCredential() {
    const { data } = await DataService.get(API.credentiaslApi.root);

    return {
      tokens: data.credentials.tokens,
    };
  }

  static async generateToken() {
    const { data } = await DataService.get(`${API.credentiaslApi.root}/${API.credentiaslApi.apiToken}`);
    return {
      apiToken: data.apiToken,
      date: data.expiration,
    };
  }
  
  static async remokeToken() {
    const { data } = await DataService.delete(`${API.credentiaslApi.root}/`)
    return{
      credentials: data.credentials,
    };
  }

}

export { CredentialsApiService };
