
import { PageHeader } from 'antd';
import { useParams } from 'react-router-dom';
import FormUserDetails from './components/FormUserDetails';
import { useDictionaryTranslation } from '../../hooks/useDictionaryTranslation';
import { Main } from '../../container/styled';
import { Cards } from '../../components/cards/frame/cards-frame';

export const UserDetails = () => {
  const { userId } = useParams();
  const { translate: t } = useDictionaryTranslation();
  return (
    <>
      <PageHeader title={t(userId != null ? t('editUser') : t('createUser'))} ghost />
      <Main>
        <Cards headless>
          <FormUserDetails />
        </Cards>
      </Main>
    </>
  );
};
