import PropTypes from 'prop-types';
import { MobileOutlined } from '@ant-design/icons';
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ActionItem } from './userDevices/styles';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';

const ActionsMobileUser = ({ userId }) => {
  const { translate: t } = useDictionaryTranslation();

  return (
    <>
      <ActionItem>
        <Link to={`/user-devices/${userId}`}>
          <Button className="buttonAction">
            <MobileOutlined />
            {t('userDevices')}
          </Button>
        </Link>
      </ActionItem>
      <ActionItem>
        <Link to={`/user-devices-logs/${userId}`}>
          <Button className="buttonAction">
            <MobileOutlined />
            {t('userDevicesLogs')}
          </Button>
        </Link>
      </ActionItem>
    </>
  );
};

ActionsMobileUser.propTypes = {
  userId: PropTypes.string,
};

export default ActionsMobileUser;
