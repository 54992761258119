import { Button, Col } from 'antd';
import styled from 'styled-components';

const ButtonAddKey = styled(Button)`
  display: flex;
  background-color: #ea4d01;
  color: white;
  margin-bottom: 30px;
  height: 40px;
  border-color: #ea4d01;
  span {
    margin: auto;
  }
`;

const ColDeleteButton = styled(Col)`
  display: flex;
  align-items: center;
  
  button{
    margin-left: 8px;
    height: 80%;
    width: 60%;

    .anticon {
      font-size: 20px;
    }
  }
`

const ApiMessage = styled.h3``;

const Tokencontainer = styled.div`
  border-radius: 10px;
  border: 2px #d6dce3 solid;
  background-color: #f4f5f7;
  padding: 10px;
`;

const TokenText = styled.span`
  border-radius: 10px;
  word-wrap: break-word;
  color: #aeb4c1;
`;

const CopyButton = styled(Button)`



`

export { ButtonAddKey, ApiMessage, TokenText, Tokencontainer, CopyButton, ColDeleteButton };
