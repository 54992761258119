import { API } from '../config/api';
import { DataService } from '../config/dataService/dataService';

class UsersService {
  static async getAll({
    page,
    search,
    specialties,
    idProfile,
    headquarter,
    headquarters,
    userIds,
    excludedIds,
    allowCurrentUser,
  }) {
    const queryParams = {
      search,
      page,
      specialties,
      idProfile,
      headquarter,
      headquarters,
      userIds,
      excludedIds,
      allowCurrentUser,
    };
    const { data } = await DataService.get(API.users.root, queryParams);
    return {
      users: data.users,
      total: data.total,
      pageSize: data.pageSize,
      page: data.page,
    };
  }

  static async getCountUsers() {
    const { data } = await DataService.get(`${API.users.root}/${API.users.profileCounts}`);
    return {
      profileCounts: data.profileCounts,
    };
  }

  static async getById(userId) {
    const { data } = await DataService.get(API.users.root + userId);
    return {
      user: data.user,
      schedulingTimes: data.schedulingTimes,
      scheduleLocks: data.scheduleLocks,
      resourcesToAdmin: data.resourcesToAdmin,
      servicesTypesAssigned: data.servicesTypesAssigned,
    };
  }

  static async createUser(userData) {
    const body = {
      username: userData.username,
      profile: userData.profile,
      email: userData.email,
      newPassword: userData.newPassword,
    };
    const { data } = await DataService.post(API.users.root, body);
    return data.user;
  }

  static async updateUser(userId, userData) {
    const body = {
      username: userData.username,
      profile: userData.profile,
      email: userData.email,
      newPassword: userData.newPassword,
    };
    const { data } = await DataService.patch(API.users.root + userId, body);
    return data.user;
  }

  static async updatePasswordUser(idUser, userData) {
    const dataNewPassword = {
      newPassword: userData.newPassword,
    };

    const { data } = await DataService.patch(`${API.users.root}/${idUser}/${API.users.password}`, dataNewPassword);
    return data.user;
  }

  static async removeUser(userId) {
    const { data } = await DataService.delete(API.users.root + userId);
    return data.user;
  }

  static async changeUserStatus(idUser, status) {
    const userDataEdit = {
      status,
    };

    const { data } = await DataService.patch(`${API.users.root}/${idUser}/${API.users.changeStatus}`, userDataEdit);
    return data.user;
  }
}

export { UsersService };
