import moment from 'moment';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  if (!(text?.length > size)) return text;
  return `${text.slice(0, size)}...`;
};

const getQueryParams = (queryString) => {
  const result = {};
  const params = new URLSearchParams(queryString);
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

const getDefaultDate = (date, hours, minutes = 0) => {
  const result = moment(date).set('hours', hours).set('minutes', minutes);
  return result;
};

const getMinDate = (date, hours, minutes = 0) => {
  const minDate = moment(date).set('minutes', minutes);
  if (minDate.get('hours') > hours) {
    return minDate.set('hours', hours);
  }
  return minDate.isValid() ? minDate : null;
};

const getMaxDate = (date, hours, minutes = 0) => {
  const maxDate = moment(date).set('minutes', minutes);
  if (maxDate.get('hours') < hours) {
    return maxDate.set('hours', hours);
  }
  return maxDate.isValid() ? maxDate : null;
};

const getNumberParam = (param) => (!param || !Number.isInteger(Number(param)) ? 1 : Number(param));
const getTextParam = (param) => (param ? String(param) : '');

function debounceFunction(func, delay) {
  let timeout;
  return async (...args) => {
    clearTimeout(timeout);
    return new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
}

function trottleFunction(func, delay) {
  let lastCall = Date.now();
  let timeout;
  return async (...args) => {
    const now = Date.now();
    if (now - lastCall > delay) {
      lastCall = now;
      func(...args);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        lastCall = Date.now();
        func(...args);
      }, delay);
    }
  };
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function midEllipsis(text, ellipsisLength) {
  const ellipsisOffset = Math.max(typeof ellipsisLength === 'number' ? ellipsisLength : 11, 11);
  if (text.length < ellipsisOffset) {
    return text;
  }
  return `${text.substring(0, ellipsisOffset - 10)}...${text.substring(text.length - 7, text.length)}`;
}

function generateQueve(handler) {
  async function* generator() {
    let args = yield;
    while (true) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const result = await handler(...args);
        args = yield { value: result };
      } catch (error) {
        yield { error };
      }
    }
  }
  const gen = generator();
  gen.next();
  return async (...args) => {
    const { value: result } = await gen.next(args);
    if (result?.error) {
      throw result.error instanceof Error ? result.error : new Error(result.error);
    }
    return result?.value;
  };
}

export {
  ellipsis,
  getQueryParams,
  getDefaultDate,
  getNumberParam,
  getTextParam,
  debounceFunction,
  getMinDate,
  getMaxDate,
  midEllipsis,
  trottleFunction,
  sleep,
  generateQueve,
};
