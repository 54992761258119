const s3Url = process.env.REACT_APP_S3_URL;
const s3AppPath = `${s3Url}/${process.env.REACT_APP_S3_BUCKET}`
const servicesReportUrl = process.env.REACT_APP_STANDAR_REPORT_URL;
const servicePdfReport = process.env.REACT_APP_PDF_REPORT;
const serviceExport = process.env.REACT_APP_SERVICE_EXPORT;

export default {
  s3UrlPath: s3Url,
  s3Path: s3AppPath,
  pdfReportPath: servicePdfReport,
  patientsImportTemplate: `${s3Url}/suite_assets/Plantilla_importacion_pacientes.xlsx`,
  customListImportTemplate: `${s3Url}/suite_assets/Plantilla_importacion_listas.xlsx`,
  APPOINTMENTS_REPORT: `${servicesReportUrl}/micita/services_report`,
  DEFAULT_BANNER: `${s3Url}/suite_assets/default-self-scheduling-banner.png`,
  APPOINTMENT_OPPORTUNITIES_REPORT: `${servicesReportUrl}/micita/opportunities_report`,
  EXPORT_SINGLE_SERVICE: `${serviceExport}/export_single_service/`
};
