import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { SearchButtonAction } from './style';

const SearchButtonComponent = ({
  primaryColor,
  textColor,
  type,
  onClick,
  onContextMenu,
  width,
  height,
  iconWidth
}) => {
  return (
    <SearchButtonAction
      primaryColor={primaryColor}
      textColor={textColor}
      type={type}
      onClick={onClick}
      onContextMenu={onContextMenu}
      width={width}
      height={height}
      iconWidth={iconWidth}
    >
      <FeatherIcon icon="search" />
    </SearchButtonAction>
  );
};

SearchButtonComponent.propTypes = {
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  iconWidth: PropTypes.string,
};

export { SearchButtonComponent };
