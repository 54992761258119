import { lazy } from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom"


const WizardAccount = lazy(() => import('../../views/WizardAccount/WizardAccount'));

const Account = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/registration`} component={WizardAccount} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Account;