import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { SearchButtonAction } from './style';

const SearchButtonComponent = ({
  primaryColor = '#44BFF0', // Default primary color
  textColor = '#ffffff',   // Default text color
  type = 'button',         // Default type
  onClick,
  onContextMenu,
  width = '40px',          // Default width
  height = '25px',         // Default height
  iconWidth = '17px',      // Default icon width
}) => {
  return (
    <SearchButtonAction
      primaryColor={primaryColor}
      textColor={textColor}
      width={width}
      height={height}
      iconWidth={iconWidth}
      type={type}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <FeatherIcon icon="search" />
    </SearchButtonAction>
  );
};

SearchButtonComponent.propTypes = {
  primaryColor: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  iconWidth: PropTypes.string,
};

export { SearchButtonComponent };
