import Styled from 'styled-components';
import { Button } from '../buttons';


const SearchButtonAction = Styled(Button)`
  box-shadow: none;
  padding: 0px 0px;
  min-width: ${(props) => props.width || '40px'};
  max-height: 25px;
  .icons{
    width: 17px;
  }
`;

export { SearchButtonAction  }