import { Col, Form } from 'antd';
import Proptype from 'prop-types';
import React from 'react';
import { UserInfoCardContainer } from './styles';

export const UserInfoCard = ({ color, text, title, margin }) => {
  return (
    <>
      <Col xs={24} md={24} style={{}}>
        <Form.Item>
          <UserInfoCardContainer variant={color} margin={margin}>
            <h4 className="title">{title}</h4>
            <p className="text">{text}</p>
          </UserInfoCardContainer>
        </Form.Item>
      </Col>
    </>
  );
};

UserInfoCard.propTypes = {
  color: Proptype.string,
  text: Proptype.string,
  title: Proptype.string,
  margin: Proptype.string,
};
