import React, { useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { Space, Tooltip, Switch, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { UserFilterButtonContent } from './UserFilterButtonContent';
import ModalPassword from './ModalPassword';
import ActionsMobileUser from './ActionsMobileUser';
import { DataTable } from '../../../components/dataTable/data-table';
import { useDictionaryTranslation } from '../../../hooks/useDictionaryTranslation';
import { useNotifications } from '../../../hooks/useNotifications';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Button } from '../../../components/buttons/buttons';
import { ProfileTypes } from '../../../config/constants/userConstants';
import { useTableUser } from '../hook/useTableUser';
import { ButtonActionDevice } from '../Style';

const editableUsers = [ProfileTypes.INTERESTED.id, ProfileTypes.TECHNICIAN.id, ProfileTypes.OPERATOR.id];

export const UsersTable = () => {
  const { translate: t } = useDictionaryTranslation();
  const [selectedProfileType, setSelectedProfileType] = useState(null);
  const { showErrorNotification, showConfirmNotification, showSuccessNotification } = useNotifications();
  const { isLoading, reloadData, tableData, getData, removeUser, changeUserStatus } = useTableUser({
    selectedProfileType,
  });
  const [modalPasswordData, setModalPasswordData] = useState({
    show: false,
    userId: null,
  });

  const getRemoveAction = (user) => () => {
    showConfirmNotification({
      title: t('areYouSure'),
      content: t('recordWillBeRemoved', { recordDescription: user.profile.identification }),
      onOk: async () => {
        try {
          await removeUser(user._id);
          showSuccessNotification({
            title: 'success',
            description: 'successRemovingRecord',
          });
          reloadData();
        } catch (error) {
          showErrorNotification({
            title: 'error',
            description: 'errorRemovingRecord',
          });
        }
      },
    });
  };

  const changeStatusAction = async (user) => {
    try {
      await changeUserStatus(user._id, !user.profile?.status);
      showSuccessNotification({
        title: 'success',
        description: 'successUpdatingRecord',
      });
      reloadData();
    } catch (error) {
      showErrorNotification({
        title: 'error',
        description: 'errorUpdatingRecord',
      });
    }
  };

  const askToChangeUserStatus = (user) => () => {
    if (user?.profile?.status) {
      showConfirmNotification({
        title: t('areYouSure'),
        content: t('userWillBeDisabled'),
        onOk: () => changeStatusAction(user),
      });
    } else {
      changeStatusAction(user);
    }
  };

  const getEditUserPasswordHandler = (user) => () => {
    setModalPasswordData({
      show: true,
      userId: user._id,
    });
  };

  const onCloseModalPassword = () => {
    setModalPasswordData({
      show: false,
      userId: null,
    });
  };

  const columns = [
    {
      key: 'userType',
      title: t('userType'),
      dataIndex: 'profile.idProfile',
      width: '20%',
      render: (_, record) => {
        const profileKey = Object.values(ProfileTypes).find((value) => record?.profile?.idProfile === value.id);
        const profileName = t(profileKey?.name ?? '');
        return profileName;
      },
    },
    {
      key: 'username',
      dataIndex: 'username',
      title: t('username'),
      width: '15%',
    },
    {
      key: 'identification',
      title: t('identification'),
      width: '14%',
      render: (_, record) => {
        return record.profile?.identification;
      },
    },
    {
      key: 'name',
      title: t('name'),
      width: '15%',
      render: (_, record) => {
        return record.profile?.name;
      },
    },
    {
      key: 'status',
      title: t('status'),
      width: '10%',
      render: (_, record) => {
        return <Switch checked={record.profile?.status} onClick={askToChangeUserStatus(record)} />;
      },
    },
    {
      key: 'actions',
      title: t('actions'),
      width: '30%',
      align: 'center',

      render: (_, record) => (
        <Space size="small">
          <Popover
            content={
              <>
                {record?.profile?.idProfile === ProfileTypes.TECHNICIAN.id ? (
                  <ActionsMobileUser userId={record._id} />
                ) : null}
              </>
            }
            placement="bottom"
            overlayInnerStyle={{ padding: '0px' }}
          >
            <ButtonActionDevice
              isTechnician={record?.profile?.idProfile === ProfileTypes.TECHNICIAN.id}
              className="item-actions-button"
              disabled={record?.profile?.idProfile !== ProfileTypes.TECHNICIAN.id}
            >
              <FeatherIcon icon="more-horizontal" className="icons" />
            </ButtonActionDevice>
          </Popover>

          <Tooltip title={t('updatePasswordText')}>
            <Button
              type="gray"
              icon={<LockOutlined />}
              onClick={getEditUserPasswordHandler(record)}
              disabled={!record.profile?.status}
            />
          </Tooltip>
          <Tooltip title={t('edit')}>
            <Link to={`/user/${record._id}`}>
              <Button type="secondary" icon={<EditOutlined />} disabled={!record.profile?.status} />
            </Link>
          </Tooltip>
          <Tooltip title={t('remove')}>
            <Button
              type="danger"
              onClick={getRemoveAction(record)}
              icon={<DeleteOutlined />}
              disabled={!editableUsers.includes(record.profile?.idProfile) || !record.profile?.status}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Cards headless>
      <DataTable
        rowKey="_id"
        onChange={getData}
        columns={columns}
        isLoading={isLoading}
        tableData={tableData}
        headerData={{
          show: true,
          itemsName: t('users'),
          actions: (
            <>
              <Link to="/user">
                <Button type="primary" icon={<PlusOutlined />} />
              </Link>
              <Popover content={<UserFilterButtonContent onSelect={setSelectedProfileType} />} placement="bottom">
                <Button style={{ background: '#00af50' }}>
                  <FeatherIcon icon="filter" style={{ color: 'white' }} />
                </Button>
              </Popover>
            </>
          ),
        }}
      />
      {modalPasswordData?.show ? (
        <ModalPassword
          userId={modalPasswordData.userId}
          show={modalPasswordData.show}
          closeModal={onCloseModalPassword}
        />
      ) : null}
    </Cards>
  );
};
