const solutionConstants = {
  TAPPTUS: {id: 1, name: 'Tapptus'},
  MIEMERGENCIA: {id: 2, name: 'Mi emergencia'},
  MICITA: {id: 3, name: 'Mi Cita'},
  MIENCUESTA: {id: 4, name: 'Mi Encuesta'},
  APHMED: {id: 5, name: 'APH Med'},
}

const solutionData = {
  [solutionConstants.TAPPTUS]: { id: 1, name: 'Tapptus'  },
  [solutionConstants.MIEMERGENCIA]: { id: 2, name: 'Mi emergencia' },
  [solutionConstants.MICITA]: { id: 3, name: 'Mi Cita' },
  [solutionConstants.MIENCUESTA]: { id: 4, name: 'Mi Encuesta' },
  [solutionConstants.APHMED]: { id: 5, name: 'APH Med' }
}

export { solutionConstants, solutionData }