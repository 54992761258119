import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import constants from '../../config/api/constants';

export const MenuLogo = () => {
  const { logo, staticPrefix } = useSelector((state) => {
    return {
      logo: state.auth.accountData?.menuLogoPlatform,
      staticPrefix: state.auth.accountData?.staticPrefix,
    };
  });

  const logoUrl = useMemo(() => {
    return logo
      ? `${constants.s3Path}/${staticPrefix}/${logo}`
      : `${constants.s3UrlPath}/suite_assets/logo_suite.png`;
  }, [logo, staticPrefix]);

  return <img src={logoUrl} alt="Menu Logo" />;
};

