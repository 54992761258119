import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row, Space } from 'antd';
import { ScheduleBlockItem } from './ScheduleBlockItem';
import { DayItemContainer, DescriptionText } from '../../styles';
import { useDictionaryTranslation } from '../../../../../hooks/useDictionaryTranslation';

export const DayItem = ({ checked, label, blocks, onChange }) => {
  const { translate: t } = useDictionaryTranslation();

  const onCheck = () => {
    onChange({ checked: !checked });
  };

  const onChangeBlock = blockIndex => data => {
    const newData = blocks.map((block, index) => {
      if (blockIndex !== index) return block;
      return {
        ...block,
        ...data,
      };
    });
    onChange({ blocks: newData });
  };

  const onRemoveBlock = blockIndex => () => {
    const newData = blocks.filter((_, index) => index !== blockIndex);
    onChange({ blocks: newData });
  };

  const addBlock = () => {
    const newBlock = { from: '00:00', to: '00:00' };
    const newBlocks = [...blocks, newBlock];
    onChange({ blocks: newBlocks });
  };

  return (
    <DayItemContainer>
      <Row>
        <Col xs={5}>
          <Checkbox checked={checked} onChange={onCheck}>
            {t(label)}
          </Checkbox>
        </Col>
        <Col xs={14}>
          {!blocks.length ? (
            <DescriptionText>{t('noSchedulesAdded')}</DescriptionText>
          ) : (
            blocks.map((block, index) => {
              return (
                <ScheduleBlockItem
                  key={index}
                  from={block.from}
                  to={block.to}
                  disabled={!checked}
                  onChange={onChangeBlock(index)}
                  onRemove={onRemoveBlock(index)}
                />
              );
            })
          )}
        </Col>
        <Col xs={5}>
          <Space size="middle">
            <Button style={{ marginTop: '10px' }} type="text" icon={<PlusOutlined />} onClick={addBlock} disabled={!checked} />
          </Space>
        </Col>
      </Row>
    </DayItemContainer>
  );
};

DayItem.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};
