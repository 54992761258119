import { Col, Row } from 'antd';
import styled from 'styled-components';

const ContentActions = styled(Row);

const ActionItem = styled(Col)`
  display: flex;
  gap: 20px;
  justify-content: left;
  :hover {
    /* font-weight: 570; */
    background-color: #e4e7ea;
  }
  .anticon {
    margin-top: 4px;
  }
  .buttonAction {
    display: flex;
    border: none;
    width: 100%;
    background: transparent;
    justify-content: left;
    color: #b8b8b8;
  }
`;

export { ContentActions, ActionItem };
